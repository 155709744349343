import * as React from "react";
import "./style.css";
import Modal from "../../atoms/Modal";
import Button from "../../atoms/Button";
import PopUpCard from "../../molecules/PopUpCard";
import TextData from "../../atoms/TextData";
import moment from "moment";
import ProfilePicture from "../../molecules/ProfilePicture";
import { employeeRoleColor } from "../../../_helpers/Functions";
import { ROUTER } from "../../../constants/env";
import ToggleButton from "../../atoms/Toggle";
import SelectInput from "../../atoms/SelectInput";
import Input from "../../atoms/Input";
import Swal from "sweetalert2";
import { useBranch } from "../../../hooks/useBranch";
import ShiftTimeInputs from "./ShiftTimeInputs";
import { ReactComponent as InfoSVG } from "./info.svg";

export default function PointeusePopupDetails(props: any) {
    const {
        display,
        onClose,
        day,
        employee,
        onValidate,
        toEdit,
        toEditShifts,
        shiftStatus,
    } = props;
    const pictureProps = {
        imgPath: ROUTER.STATIC_IMG(employee?.picture),
        width: "50px",
        height: "50px",
        borderRadius: "50%",
        style: { borderRadius: "50%" },
    };
    const { branchSettings } = useBranch();
    const [pauseHistory, setPauseHistory] = React.useState<any>([]);
    React.useEffect(() => {
        let pauseArr: any = [];
        if (toEditShifts?.length > 0) {
            for (let shift of toEditShifts)
                if (shift.pauseHistory) {
                    if (
                        shift.pauseHistory[0]?.time &&
                        shift.pauseHistory[1]?.time &&
                        shift.pauseTotal !== null &&
                        shift.pauseTotal !== undefined
                    ) {
                        pauseArr.push({
                            from: shift.pauseHistory[0]?.time,
                            to: shift.pauseHistory[1]?.time,
                            total: shift.pauseTotal,
                        });
                    }
                }
        }
        setPauseHistory([...pauseArr]);
    }, [toEditShifts]);
    const HeaderTitle = () => {
        return (
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    margin: "0 20px",
                    padding: "5px",
                }}
            >
                <TextData
                    variant="label"
                    width="auto"
                    size="22px"
                    fontWeight="700"
                    color="#2A346D"
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "10px",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignContent: "center",
                            width: "fit-content",
                            height: "fit-content",
                        }}
                    >
                        <ProfilePicture
                            profileImage={employee?.picture}
                            firstName={employee?.fname}
                            lastName={employee?.lname}
                            pictureProps={pictureProps}
                            altColor={employeeRoleColor(employee?.role)}
                        />
                    </div>
                    <div>
                        <div>
                            {employee?.fname} {employee?.lname}
                        </div>
                        <div
                            style={{
                                fontSize: "18px",
                                fontWeight: "600",
                            }}
                        >
                            {moment(day, "YYYY-MM-DD")
                                .locale("fr")
                                .format("dddd D MMMM YYYY")}
                        </div>
                    </div>
                </TextData>
            </div>
        );
    };
    const [pointage, setPointage] = React.useState({
        absence: "",
        repas: null,
        start: "",
        end: "",
        deleted: false,
        isAbsent: false,
        comment: "",
        extraDays: "",
        shiftId: "",
    });
    const [shifts, setShifts] = React.useState([]);
    // React.useEffect(() => {
    //     if (toEdit) {
    //         setPointage({
    //             absence: toEdit?.absence,
    //             repas: toEdit?.repas,
    //             start: toEdit?.shiftrenumere?.arriver?.[0]?.time,
    //             end:toEdit?.shiftrenumere?.quitter?.[0]?.time,
    //             deleted: toEdit?.deleted,
    //             isAbsent: toEdit?.absent,
    //             comment: toEdit?.comment,
    //             extraDays: toEdit?.extraDays,
    //             shiftId: toEdit?.shiftId,
    //         });
    //     } else {
    //         setPointage({
    //             absence: "",
    //             repas: null,
    //             start: "",
    //             end: "",
    //             deleted: false,
    //             isAbsent: false,
    //             comment: "",
    //             extraDays: "",
    //             shiftId: "",
    //         });
    //     }
    // }, [toEdit]);

    React.useEffect(() => {
        let shiftsArr: any = [];
        if (toEditShifts !== null) {
            for (let item of toEditShifts) {
                let comments: any[] = [];
                if (item.shiftbadge.arriver[0]?.comment) {
                    comments.push({
                        comment: item.shiftbadge.arriver[0]?.comment,
                        title: item.shiftbadge.arriver[0].type,
                    });
                }
                if (item.pauseHistory) {
                    item.pauseHistory?.map((pause: any) => {
                        if (pause?.comment) {
                            comments.push({
                                comment: pause?.comment,
                                title: pause.type,
                            });
                        }
                    });
                }

                if (item.shiftbadge.quitter[0]?.comment) {
                    comments.push({
                        comment: item.shiftbadge.quitter[0]?.comment,
                        title: item.shiftbadge.quitter[0].type,
                    });
                }
                shiftsArr.push({
                    isAbsent: item?.absent,
                    absence: item?.absence,
                    deleted: item?.deleted,
                    repas:
                        item?.repas === "1" || item?.repas === "2"
                            ? Number(item?.repas)
                            : item?.repas,
                    extraDays: item?.extraDays,
                    comment: item?.comment,
                    start: item?.shiftrenumere?.arriver?.[0]?.time || "",
                    end: item?.shiftrenumere?.quitter?.[0]?.time || "",
                    pause: item?.pause,
                    shiftId: item?._id,
                    comments,
                });
            }
            setShifts(shiftsArr);
        } else setShifts([]);
    }, [toEditShifts]);
    const [isLoading, setIsLoading] = React.useState(false);
    const handleValidation = async () => {
        setIsLoading(true);
        let isDisabled = false;
        if (shifts.length > 0) {
            for (let shift of shifts as any) {
                if (shift.isAbsent && shift.absence === "") {
                    isDisabled = true;
                    break;
                }
            }
        }
        if ((pointage.isAbsent && pointage.absence === "") || isDisabled) {
            Swal.fire({
                title: "Erreur!",
                text: "Veuillez préciser le type d'absence",
                icon: "error",
                confirmButtonText: "Ok",
            });
        } else {
            let ok = false;
            if (shifts.length > 0) {
                if (pointage.start || pointage.end) {
                    ok = await onValidate([...shifts, pointage]);
                } else ok = await onValidate([...shifts]);
            } else {
                if (pointage.isAbsent || pointage.deleted) {
                    ok = await onValidate([...shifts, pointage]);
                } else ok = await onValidate([...shifts]);
            }
            if (ok) handleClose();
        }
        setIsLoading(false);
    };
    React.useEffect(() => {
        if (!display) {
            setPointage({
                absence: "",
                repas: null,
                start: "",
                end: "",
                deleted: false,
                isAbsent: false,
                comment: "",
                extraDays: "",
                shiftId: "",
            });
        }
    }, [display]);
    const handleClose = () => {
        onClose && onClose();
    };

    let absenceTypes = [
        { label: "Évènement familial", value: "Évènement familial" },
        {
            label: "Indisponibilité ponctuelle",
            value: "Indisponibilité ponctuelle",
        },
        { label: "Repos hebdomadaire", value: "Repos hebdomadaire" },
        { label: "Absence injustifiée", value: "Absence injustifiée" },
        { label: "Absence justifiée", value: "Absence justifiée" },
        { label: "Accident du travail", value: "Accident du travail" },
        // { label: "Arrêt maladie", value: "Arrêt maladie" },
        { label: "Chômage technique", value: "Chômage technique" },
        { label: "Formation", value: "Formation" },
        // { label: "Jour férié", value: "Jour férié" },
        { label: "Maladie professionnelle", value: "Maladie professionnelle" },
        {
            label: "Maladie non professionnelle",
            value: "Maladie non professionnelle",
        },
        {
            label: "Mise à pied conservatoire",
            value: "Mise à pied conservatoire",
        },
        {
            label: "Mise à pied disciplinaire",
            value: "Mise à pied disciplinaire",
        },
        {
            label: "Repos compensateur d'habillement",
            value: "Repos compensateur d'habillement",
        },
        {
            label: "Repos compensateur de nuit",
            value: "Repos compensateur de nuit",
        },
        { label: "Visite médicale", value: "Visite médicale" },
        {
            label: "Réduction du temps de travail (RTT)",
            value: "Réduction du temps de travail (RTT)",
        },
    ];
    const repasOptions = [
        {
            label: "Pas de repas (par défaut)",
            value: null,
        },
        {
            label: "1 repas dû",
            value: "0",
        },
        {
            label: "1 repas pris",
            value: 1,
        },
        {
            label: "2 repas pris",
            value: 2,
        },
    ];

    return (
        <Modal modalEnabled={display} onClose={handleClose}>
            <div className="adding-note-container">
                <PopUpCard
                    containerWidth={pauseHistory?.length ? "900px" : "600px"}
                    display={display}
                    onClose={handleClose}
                    header={<HeaderTitle />}
                    widthCloseIcon="24px"
                    styleCloseIcon={{
                        height: "auto",
                        marginRight: "37px",
                        width: "24px",
                        border: "1px solid #edeff533",
                        borderTopRightRadius: "20px",
                    }}
                    headerStyle={{
                        fontSize: "22px",
                        fontWeight: "700",
                        color: "#2A346D",
                    }}
                    popupHeaderStyle={{
                        height: "80px",
                        backgroundColor: "#edeff54c",
                        borderBottom: "1px solid #C7CBE2",
                    }}
                    button1Text="Annuler"
                    onClickButton1={handleClose}
                    button2Text={"Valider"}
                    button2Style={{
                        color: "white",
                        fontSize: "14px",
                        opacity: isLoading ? "0.5" : "1",
                        cursor: isLoading ? "wait" : "pointer",
                        backgroundColor: isLoading ? "gray" : "#2A8BAB",
                    }}
                    onClickButton2={handleValidation}
                >
                    <div
                        className=""
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "10px",
                            color: "#2a346d",
                            maxHeight: "70vh",
                            overflowY: "auto",
                            paddingTop: "20px",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                            }}
                        >
                            <div
                                style={{
                                    padding: "10px 30px",
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "20px",
                                    color: "#2a346d",
                                    width: pauseHistory?.length
                                        ? "70%"
                                        : "100%",
                                }}
                            >
                                {shifts?.map((shift: any, index: number) => {
                                    if (
                                        !(
                                            shift.start ||
                                            shift.end ||
                                            shift.pause
                                        ) &&
                                        index > 0
                                    ) {
                                        return null;
                                    }
                                    return (
                                        <ShiftPointage
                                            key={shift.shiftId}
                                            data={shift}
                                            absenceTypes={absenceTypes}
                                            setShifts={setShifts}
                                            index={index}
                                            repasOptions={repasOptions}
                                            useRepas={branchSettings.useRepas}
                                            shiftStatus={shiftStatus}
                                        />
                                    );
                                })}
                                {shiftStatus !== "deleted" &&
                                    shiftStatus !== "absent" && (
                                        <>
                                            {!shifts?.length && (
                                                <>
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            justifyContent:
                                                                "space-between",
                                                        }}
                                                    >
                                                        <div>
                                                            Déclarer l'employé
                                                            absent
                                                        </div>
                                                        <ToggleButton
                                                            isChecked={
                                                                pointage.isAbsent
                                                            }
                                                            onClick={() =>
                                                                setPointage(
                                                                    (
                                                                        prev: any,
                                                                    ) => {
                                                                        if (
                                                                            prev.deleted &&
                                                                            !prev.isAbsent
                                                                        ) {
                                                                            return {
                                                                                ...prev,
                                                                                isAbsent:
                                                                                    true,
                                                                                absence:
                                                                                    "",
                                                                                deleted:
                                                                                    false,
                                                                            };
                                                                        } else {
                                                                            return {
                                                                                ...prev,
                                                                                absence:
                                                                                    "",
                                                                                isAbsent:
                                                                                    !prev.isAbsent,
                                                                            };
                                                                        }
                                                                    },
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                    {pointage.isAbsent && (
                                                        <div>
                                                            <SelectInput
                                                                title="Type d'absence"
                                                                required
                                                                placeholder="Sélectionner un type"
                                                                options={absenceTypes.sort(
                                                                    (a, b) => {
                                                                        const labelA =
                                                                            a.label
                                                                                .normalize(
                                                                                    "NFD",
                                                                                )
                                                                                .replace(
                                                                                    /[\u0300-\u036f]/g,
                                                                                    "",
                                                                                )
                                                                                .toUpperCase();
                                                                        const labelB =
                                                                            b.label
                                                                                .normalize(
                                                                                    "NFD",
                                                                                )
                                                                                .replace(
                                                                                    /[\u0300-\u036f]/g,
                                                                                    "",
                                                                                )
                                                                                .toUpperCase();

                                                                        return labelA.localeCompare(
                                                                            labelB,
                                                                            undefined,
                                                                            {
                                                                                sensitivity:
                                                                                    "accent",
                                                                            },
                                                                        );
                                                                    },
                                                                )}
                                                                selectedValue={
                                                                    pointage.absence
                                                                        ? {
                                                                              label: pointage.absence,
                                                                              value: pointage.absence,
                                                                          }
                                                                        : undefined
                                                                }
                                                                onSelectOption={(
                                                                    option,
                                                                ) =>
                                                                    setPointage(
                                                                        (
                                                                            prev: any,
                                                                        ) => ({
                                                                            ...prev,
                                                                            absence:
                                                                                option.value,
                                                                        }),
                                                                    )
                                                                }
                                                                style={{
                                                                    // padding: "0 10px",
                                                                    height: "48px",
                                                                    width: "100%",
                                                                    borderRadius:
                                                                        "8px",
                                                                    fontSize:
                                                                        "12px",
                                                                }}
                                                            ></SelectInput>
                                                        </div>
                                                    )}
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            justifyContent:
                                                                "space-between",
                                                        }}
                                                    >
                                                        <div>
                                                            Ignorer ce pointage
                                                        </div>
                                                        <ToggleButton
                                                            isChecked={
                                                                pointage.deleted
                                                            }
                                                            onClick={() =>
                                                                setPointage(
                                                                    (
                                                                        prev: any,
                                                                    ) => {
                                                                        if (
                                                                            !prev.deleted &&
                                                                            prev.isAbsent
                                                                        ) {
                                                                            return {
                                                                                ...prev,
                                                                                isAbsent:
                                                                                    false,
                                                                                absence:
                                                                                    "",
                                                                                deleted:
                                                                                    true,
                                                                            };
                                                                        } else {
                                                                            return {
                                                                                ...prev,
                                                                                absence:
                                                                                    "",
                                                                                deleted:
                                                                                    !prev.deleted,
                                                                            };
                                                                        }
                                                                    },
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                </>
                                            )}
                                            <div>
                                                <div>
                                                    Ajouter un nouveau shift
                                                </div>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "end",
                                                        gap: "15px",
                                                    }}
                                                >
                                                    <ShiftTimeInputs
                                                        setPointage={
                                                            setPointage
                                                        }
                                                        pointage={pointage}
                                                    />
                                                    {branchSettings.useRepas && (
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                justifyContent:
                                                                    "space-between",
                                                                alignItems:
                                                                    "center",
                                                            }}
                                                        >
                                                            <SelectInput
                                                                title="Repas"
                                                                options={
                                                                    repasOptions
                                                                }
                                                                isSearchable={
                                                                    false
                                                                }
                                                                selectedValue={{
                                                                    label:
                                                                        repasOptions.find(
                                                                            (
                                                                                option: any,
                                                                            ) =>
                                                                                option.value ===
                                                                                pointage.repas,
                                                                        )
                                                                            ?.label ||
                                                                        "Pas de repas (par défaut)",
                                                                    value: pointage.repas,
                                                                }}
                                                                onSelectOption={
                                                                    (
                                                                        option,
                                                                    ) => {
                                                                        setPointage(
                                                                            (
                                                                                prev: any,
                                                                            ) => ({
                                                                                ...prev,
                                                                                repas: option.value,
                                                                            }),
                                                                        );
                                                                    }
                                                                    // setShiftData((prev: any) => ({
                                                                    //     ...prev,
                                                                    //     repas: option.label,
                                                                    // }))
                                                                }
                                                                style={{
                                                                    height: "40px",
                                                                    width: "200px",
                                                                    color: "#C7CBE2",
                                                                }}
                                                                captionColor="#2a346d"
                                                                captionSize="12px"
                                                                optionColor="#4C5690"
                                                                optionSize="12px"
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            </div>

                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent:
                                                        "space-between",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <div>
                                                    Ajouter un jour
                                                    supplémentaire
                                                </div>
                                                <SelectInput
                                                    menuPlacement={"top"}
                                                    title=""
                                                    options={[
                                                        {
                                                            label: "-0.5",
                                                            value: "-0.5",
                                                        },
                                                        {
                                                            label: "0",
                                                            value: "0",
                                                        },
                                                        {
                                                            label: "0.5",
                                                            value: "0.5",
                                                        },
                                                        {
                                                            label: "1",
                                                            value: "1",
                                                        },
                                                        {
                                                            label: "1.5",
                                                            value: "1.5",
                                                        },
                                                        {
                                                            label: "2",
                                                            value: "2",
                                                        },
                                                    ]}
                                                    isSearchable={false}
                                                    selectedValue={{
                                                        label:
                                                            pointage.extraDays ||
                                                            "0",
                                                        value: pointage.extraDays,
                                                    }}
                                                    onSelectOption={(
                                                        option,
                                                    ) => {
                                                        setPointage(
                                                            (prev: any) => ({
                                                                ...prev,
                                                                extraDays:
                                                                    option.value,
                                                            }),
                                                        );
                                                    }}
                                                    style={{
                                                        height: "40px",
                                                        width: "100px",
                                                        color: "#C7CBE2",
                                                    }}
                                                    captionColor="#2a346d"
                                                    captionSize="12px"
                                                    optionColor="#4C5690"
                                                    optionSize="12px"
                                                />
                                            </div>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    padding: "10px 0px",
                                                }}
                                            >
                                                <div>Note</div>
                                                <textarea
                                                    placeholder="Écrire un commentaire."
                                                    value={pointage?.comment}
                                                    onChange={(e) => {
                                                        setPointage(
                                                            (prev: any) => ({
                                                                ...prev,
                                                                comment:
                                                                    e.target
                                                                        .value,
                                                            }),
                                                        );
                                                    }}
                                                    style={{
                                                        borderRadius: "4px",
                                                        border: "1px solid #C7CBE2",
                                                        padding: "10px 8px",
                                                        color: "#4C5690",
                                                        fontSize: "14px",
                                                        fontWeight: "400",
                                                        width: "100%",
                                                        height: "70px",
                                                    }}
                                                />
                                            </div>
                                        </>
                                    )}
                            </div>
                            {pauseHistory?.length ? (
                                <div
                                    style={{
                                        padding: "10px 30px",
                                        borderLeft:
                                            "1px solid rgb(199 203 226)",
                                    }}
                                >
                                    <div
                                        style={{
                                            fontSize: "large",
                                        }}
                                    >
                                        Temps de pause
                                    </div>
                                    {pauseHistory?.map((pause: any) => (
                                        <div style={{ fontSize: "medium" }}>
                                            De {pause.from} à {pause.to}{" "}
                                            {pause.total && (
                                                <span
                                                    style={{
                                                        fontSize: "small",
                                                        fontWeight: "bold",
                                                    }}
                                                >
                                                    ({pause.total} minutes)
                                                </span>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>
                </PopUpCard>
            </div>
        </Modal>
    );
}
const ShiftPointage = (props: any) => {
    const {
        data,
        absenceTypes,
        setShifts,
        index,
        repasOptions,
        useRepas,
        shiftStatus,
    } = props;

    return (
        <div
            style={{
                border: "1px solid #2a8bab",
                padding: "10px",
                borderRadius: "5px",
                display: "flex",
                flexDirection: "column",
                gap: 12,
            }}
        >
            {data?.deleted ? (
                <div
                    style={{
                        color: "#222",
                        backgroundColor: "#ff0",
                        padding: "2px 6px",
                        borderRadius: "5px",
                        width: "fit-content",
                    }}
                >
                    Ignoré
                </div>
            ) : (
                <div
                    style={{
                        backgroundColor: "#2a8bab",
                        color: "white",
                        fontSize: "18px",
                        marginBottom: "10px",
                        padding: "10px",
                        borderRadius: "5px",
                        fontWeight: "bold",
                    }}
                >
                    {data.start} - {data.end}
                </div>
            )}

            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <div>Déclarer l'employé absent</div>
                <ToggleButton
                    isChecked={data.isAbsent}
                    onClick={() =>
                        setShifts((prev: any) => {
                            let prevShift = { ...prev[index] };
                            if (prev[index].deleted && !prev[index].isAbsent) {
                                prevShift = {
                                    ...prev[index],
                                    isAbsent: true,
                                    absence: "",
                                    deleted: false,
                                };
                            } else {
                                prevShift = {
                                    ...prev[index],
                                    absence: "",
                                    isAbsent: !prev[index].isAbsent,
                                };
                            }
                            let prevs = [...prev];
                            prevs[index] = prevShift;
                            return prevs;
                        })
                    }
                />
            </div>
            {data.isAbsent && (
                <div>
                    {/* <SelectInput
                    title=""
                    options={primeTypes.map((type) => ({
                        value: type._id,
                        label: type.name,
                    }))}
                    selectedValue={prime.typeAcompte.value}
                    onSelectOption={(option) => {
                        onChange("typeAcompte", option);
                    }}
                    isSearchable={false}
                    style={{
                        // padding: "0 10px",
                        height: "48px",
                        width: "100%",
                        borderRadius: "8px",
                        fontSize: "12px",
                    }}
                    placeholder="Sélectionner un type"
                ></SelectInput> */}
                    <SelectInput
                        title="Type d'absence"
                        placeholder="Sélectionner un type"
                        required
                        isSearchable={false}
                        options={absenceTypes.sort((a: any, b: any) => {
                            const labelA = a.label
                                .normalize("NFD")
                                .replace(/[\u0300-\u036f]/g, "")
                                .toUpperCase();
                            const labelB = b.label
                                .normalize("NFD")
                                .replace(/[\u0300-\u036f]/g, "")
                                .toUpperCase();

                            return labelA.localeCompare(labelB, undefined, {
                                sensitivity: "accent",
                            });
                        })}
                        selectedValue={
                            data.absence
                                ? {
                                      label: data.absence,
                                      value: data.absence,
                                  }
                                : undefined
                        }
                        onSelectOption={(option) =>
                            setShifts((prev: any) => {
                                let prevs = [...prev];
                                prevs[index] = {
                                    ...prevs[index],
                                    absence: option.value,
                                };
                                return prevs;
                            })
                        }
                        style={{
                            // padding: "0 10px",
                            height: "48px",
                            width: "100%",
                            borderRadius: "8px",
                            fontSize: "12px",
                        }}
                    ></SelectInput>
                </div>
            )}
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <div>Ignorer ce pointage</div>
                <ToggleButton
                    isChecked={data.deleted}
                    onClick={() =>
                        setShifts((prev: any) => {
                            let prevShift = { ...prev[index] };
                            if (!prev[index].deleted && prev[index].isAbsent) {
                                prevShift = {
                                    ...prev[index],
                                    isAbsent: false,
                                    absence: "",
                                    deleted: true,
                                };
                            } else {
                                prevShift = {
                                    ...prev[index],
                                    absence: "",
                                    deleted: !prev[index].deleted,
                                };
                            }
                            let prevs = [...prev];
                            prevs[index] = prevShift;
                            return prevs;
                        })
                    }
                />
            </div>
            {!data.deleted && !data.isAbsent && (
                <>
                    {useRepas && (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                        >
                            <div>Ajouter repas</div>
                            <SelectInput
                                title=""
                                options={repasOptions}
                                isSearchable={false}
                                selectedValue={{
                                    label:
                                        repasOptions.find(
                                            (option: any) =>
                                                option.value === data.repas,
                                        )?.label || "Pas de repas (par défaut)",
                                    value: data.repas,
                                }}
                                onSelectOption={(option) => {
                                    setShifts((prev: any) => {
                                        let prevs = [...prev];
                                        prevs[index] = {
                                            ...prevs[index],
                                            repas: option.value,
                                        };
                                        return prevs;
                                    });
                                }}
                                style={{
                                    height: "40px",
                                    width: "200px",
                                    color: "#C7CBE2",
                                }}
                                captionColor="#2a346d"
                                captionSize="12px"
                                optionColor="#4C5690"
                                optionSize="12px"
                            />
                        </div>
                    )}

                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <div>Ajouter un jour supplémentaire</div>
                        <SelectInput
                            menuPlacement={"top"}
                            title=""
                            options={[
                                {
                                    label: "-0.5",
                                    value: "-0.5",
                                },
                                { label: "0", value: "0" },
                                {
                                    label: "0.5",
                                    value: "0.5",
                                },
                                { label: "1", value: "1" },
                                {
                                    label: "1.5",
                                    value: "1.5",
                                },
                                { label: "2", value: "2" },
                            ]}
                            isSearchable={false}
                            selectedValue={{
                                label: data.extraDays || "0",
                                value: data.extraDays,
                            }}
                            onSelectOption={(option) => {
                                setShifts((prev: any) => {
                                    let prevs = [...prev];
                                    prevs[index] = {
                                        ...prevs[index],
                                        extraDays: option.value,
                                    };
                                    return prevs;
                                });
                            }}
                            style={{
                                height: "40px",
                                width: "100px",
                                color: "#C7CBE2",
                            }}
                            captionColor="#2a346d"
                            captionSize="12px"
                            optionColor="#4C5690"
                            optionSize="12px"
                        />
                    </div>
                    {data?.comments?.length ? (
                        <div>
                            <table
                                style={{
                                    width: "100%",
                                }}
                            >
                                <caption
                                    style={{
                                        textAlign: "left",
                                    }}
                                >
                                    Commentaires lors du pointage
                                </caption>

                                <tbody>
                                    {data?.comments?.map((item: any) => {
                                        return (
                                            <tr>
                                                <th
                                                    style={{
                                                        fontSize: "12px",
                                                        textAlign: "left",
                                                        padding: "5px",
                                                        border: "1px solid #2a8bab",
                                                        width: "20%",
                                                    }}
                                                >
                                                    {item.title === "ARRIVER"
                                                        ? "Arrivée"
                                                        : item.title ===
                                                            "QUITTER_PAUSE"
                                                          ? "Départ en pause"
                                                          : item.title ===
                                                              "ARRIVER_PAUSE"
                                                            ? "Retour de pause"
                                                            : item.title ===
                                                                  "QUITTER" &&
                                                              "Départ"}
                                                </th>
                                                <td
                                                    style={{
                                                        fontSize: "12px",
                                                        textAlign: "left",
                                                        padding: "5px",
                                                        border: "1px solid #2a8bab",
                                                    }}
                                                >
                                                    {item?.comment}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    ) : (
                        <></>
                    )}
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            padding: "10px 0px",
                        }}
                    >
                        <div>Note</div>
                        <textarea
                            placeholder="Écrire un commentaire."
                            value={data?.comment}
                            onChange={(e) => {
                                setShifts((prev: any) => {
                                    let prevs = [...prev];
                                    prevs[index] = {
                                        ...prevs[index],
                                        comment: e.target.value,
                                    };
                                    return prevs;
                                });
                            }}
                            style={{
                                borderRadius: "4px",
                                border: "1px solid #C7CBE2",
                                padding: "10px 8px",
                                color: "#4C5690",
                                fontSize: "14px",
                                fontWeight: "400",
                                width: "100%",
                                height: "70px",
                            }}
                        />
                    </div>
                </>
            )}
        </div>
    );
};
