import React, { useEffect, useMemo, useState } from "react";
import "./style.css";
import Modal from "../../atoms/Modal";
import PopUpCard from "../../molecules/PopUpCard";
import TextData from "../../atoms/TextData";
import Button from "../../atoms/Button";
import SelectInput from "../../atoms/SelectInput";
import Input from "../../atoms/Input";
import { ReactComponent as UploadSVG } from "./upload.svg";
import { useEmployees } from "../../../hooks/useEmployees";
import Swal from "sweetalert2";
import Loading from "../../atoms/Loading";

export default function AddDocumentCard(props: AddDocumentCardProps) {
    const { display, onClose, documentToEdit } = props;

    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [fileName, setFileName] = useState<string>("");
    const [loading, setLoading] = React.useState(false);
    const [folder, setFolder] = useState<{
        label: string;
        value: string;
    } | null>(null);
    const [isErrFileName, setIsErrFileName] = React.useState(false);
    const [isErrFolder, setIsErrVisibility] = React.useState(false);
    const [isErrImportedFile, setIsImportedFile] = React.useState(false);

    const { documents, addNewDocument, editExistingDocument, employeeForEdit } =
        useEmployees();

    const { folders } = documents;
    const fileInputRef = React.useRef<HTMLInputElement>(null);
    const handleFileChange = (event: any) => {
        if (event.target.files.length > 0) {
            setIsImportedFile(false);
            setSelectedFile(event.target.files[0]);
        } else {
            setIsImportedFile(true);
            setSelectedFile(null);
        }
    };

    const handleClick = () => {
        const refElem = fileInputRef?.current;
        if (refElem) {
            refElem.click();
        }
    };

    const handleSubmitDocument = async () => {
        const hasError =
            !fileName ||
            fileName.length === 0 ||
            (!documentToEdit && !folder) ||
            (!documentToEdit && !selectedFile);
        if (hasError) {
            Swal.fire({
                title: "Erreur!",
                text: "Veuillez corriger les champs en rouge pour continuer",
                icon: "error",
                timer: 1500,
                timerProgressBar: true,
            });
            setIsErrFileName(!fileName || fileName.length <= 0);
            setIsImportedFile(!selectedFile);
            return;
        }
        setLoading(true);
        if (documentToEdit) {
            await editExistingDocument(
                documentToEdit._id,
                folder?.value || "",
                fileName,
            );
        } else {
            await addNewDocument(
                selectedFile,
                fileName,
                employeeForEdit._id,
                folder?.value || "",
            );
        }
        setLoading(false);
        setFileName("");
        setFolder(null);
        setSelectedFile(null);
        setIsErrFileName(false);
        setIsErrVisibility(false);
        setIsImportedFile(false);
        onClose && onClose();
    };

    const foldersList = useMemo(
        () => [
            {
                label: "Fichiers déposés par le collaborateur",
                value: null,
            },
            ...(folders
                ? folders.map((folder: any) => ({
                      label: folder.name,
                      value: folder._id,
                  }))
                : []), // Use an empty array if folders is null or undefined
        ],
        [folders],
    );

    useEffect(() => {
        if (selectedFile) {
            setFileName(selectedFile.name);
        } else {
            setFileName("");
        }
    }, [selectedFile]);

    useEffect(() => {
        if (documentToEdit) {
            setFileName(documentToEdit.fileName);
            setFolder(
                foldersList.find(
                    (item: any) =>
                        documentToEdit.folder === item.value ||
                        documentToEdit.folder?._id === item.value,
                ),
            );
        } else {
            setLoading(false);
            setFileName("");
            setFolder(null);
            setSelectedFile(null);
            setIsErrFileName(false);
            setIsErrVisibility(false);
            setIsImportedFile(false);
        }
    }, [documentToEdit, folders]);

    return (
        <Modal modalEnabled={display} onClose={onClose}>
            <div className="duplicate-container">
                <PopUpCard
                    containerWidth="400px"
                    display={display}
                    onClose={onClose}
                    header={
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                margin: "25px auto 25px 40px",
                            }}
                        >
                            <TextData
                                variant="label"
                                width="auto"
                                size="22px"
                                fontWeight="700"
                                color="#2A346D"
                            >
                                {documentToEdit
                                    ? "Modifier un document"
                                    : "Ajouter un document"}
                            </TextData>
                        </div>
                    }
                    widthCloseIcon="24px"
                    styleCloseIcon={{
                        height: "auto",
                        marginRight: "37px",
                        width: "24px",
                        border: "1px solid #edeff533",
                        borderTopRightRadius: "20px",
                        cursor: "pointer",
                    }}
                    headerStyle={{
                        fontSize: "22px",
                        fontWeight: "700",
                        color: "#2A346D",
                    }}
                    popupHeaderStyle={{
                        height: "80px",
                        backgroundColor: "#edeff54c",
                        borderBottom: "1px solid #C7CBE2",
                    }}
                    button2Text={documentToEdit ? "Modifier" : "Ajouter"}
                    button1Text="Annuler"
                    // button2Text='Cancel'
                    button2Style={{ backgroundColor: "#2a8bab" }}
                    button1Style={{
                        backgroundColor: "#edeff5",
                        color: "#2a346d",
                    }}
                    onClickButton2={handleSubmitDocument}
                    onClickButton1={onClose}
                >
                    {loading ? (
                        <div style={{ height: 400 }}>
                            <Loading width="400px" height="200px" />
                        </div>
                    ) : (
                        <div className="add-document-form-container">
                            {!documentToEdit && (
                                <div className="documents-categories-container">
                                    <TextData
                                        width="auto"
                                        // fontWeight="700"
                                        variant="label"
                                        size="16px"
                                        color="#6F78AB"
                                    >
                                        Fichier
                                        <span className="text-[red]">*</span>
                                    </TextData>
                                    <input
                                        ref={fileInputRef}
                                        type="file"
                                        accept=".pdf,.doc,.docx,.txt"
                                        hidden
                                        id="fileInput"
                                        onChange={handleFileChange}
                                    />
                                    <label htmlFor="fileInput">
                                        <Button
                                            width="100%"
                                            backgroundColor="#CFE0E6"
                                            height="48px"
                                            style={{
                                                border: "1px solid #2A8BAB",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                gap: "10px",
                                            }}
                                            padding="7px"
                                            onClick={handleClick}
                                        >
                                            <UploadSVG
                                                height={20}
                                                width={20}
                                                color="#2A8BAB"
                                            />
                                            <TextData
                                                width="auto"
                                                fontWeight="500"
                                                variant="label"
                                                size="16px"
                                                color="#2A8BAB"
                                                style={{ cursor: "pointer" }}
                                            >
                                                {fileName?.slice(0, 25) ||
                                                    "Importer fichier"}
                                                {fileName?.length > 25
                                                    ? "..."
                                                    : ""}
                                            </TextData>
                                        </Button>
                                    </label>
                                    {isErrImportedFile && (
                                        <TextData size="13px" color="#fb3640">
                                            Ce champ est obligatoire
                                        </TextData>
                                    )}
                                </div>
                            )}
                            <div className="doument-name-container">
                                <Input
                                    type="text"
                                    placeholder="Nom du fichier"
                                    defaultValue={selectedFile?.name}
                                    value={fileName}
                                    captionText="Nom du fichier"
                                    inputWidth="100%"
                                    borderColor="#C7CBE2"
                                    borderWidth={1}
                                    inputRadius="4px"
                                    textColor="#4C5690"
                                    textSize={14}
                                    iconLocation="right"
                                    style={{
                                        inputStyle: {
                                            minHeight: "48px",
                                            fontWeight: "400",
                                            paddingLeft: "15px",
                                            paddingRight: "15px",
                                        },
                                        captionStyle: {
                                            fontWeight: "500",
                                        },
                                    }}
                                    // maxTextLength={50}
                                    required
                                    onChange={setFileName}
                                />
                                {isErrFileName && (
                                    <TextData size="13px" color="#fb3640">
                                        Ce champ est obligatoire
                                    </TextData>
                                )}
                            </div>
                            {((documentToEdit && folder) ||
                                !documentToEdit) && (
                                <div className="documents-categories-container">
                                    <TextData
                                        width="auto"
                                        // fontWeight="700"
                                        variant="label"
                                        size="16px"
                                        color="#6F78AB"
                                    >
                                        Dossier
                                        <span className="text-[red]">*</span>
                                    </TextData>
                                    <SelectInput
                                        title=""
                                        placeholder=""
                                        options={foldersList?.slice(
                                            documentToEdit && folder === null
                                                ? 0
                                                : 1,
                                        )}
                                        selectedValue={folder}
                                        style={{ height: "48px" }}
                                        onSelectOption={setFolder}
                                    />
                                    {isErrFolder && (
                                        <TextData size="13px" color="#fb3640">
                                            Ce champ est obligatoire
                                        </TextData>
                                    )}
                                </div>
                            )}
                        </div>
                    )}
                </PopUpCard>
            </div>
        </Modal>
    );
}
interface AddDocumentCardProps {
    display: boolean;
    onClose?: () => void;
    documentToEdit?: any;
}
