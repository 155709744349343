import React from "react";
import Modal from "../../organism/Modal";
import Stepper from "../../molecules/Stepper";
import CreateEmployeeInfoForm from "../../organism/CreateEmployeeInfoForm";
import CreateEmployeeRolesAndAccess from "../../organism/CreateEmployeeRolesAndAccess";
import { useIntl } from "react-intl";
import { Helmet } from "react-helmet";
import { ArrowRight } from "../../../Icons";
import Button from "../../atoms/ZButton";
import Swal from "sweetalert2";
import { Rect } from "@dnd-kit/core/dist/utilities";

export default function EmployesCreate(
    {
        show,
        onHide,
        employee,
        onChange,
        onSubmit,
        sites,
        onBlur,
        checkError,
        validate,
        confirmLoading,
        departements,
        multiplePlanning,
    }: any /* EmployeeCreateInterface*/,
) {
    const intl = useIntl();
    const steps = [
        {
            title: intl.formatMessage({ id: "EMPLOYEE.EDIT.PERSONAL_INFOS" }),
            component: (
                <CreateEmployeeInfoForm
                    onBlur={onBlur}
                    onChange={onChange}
                    employee={employee}
                />
            ),
        },
        {
            title: intl.formatMessage({ id: "EMPLOYEE.EDIT.ROLE_PICKER" }),
            component: (
                <CreateEmployeeRolesAndAccess
                    onBlur={onBlur}
                    onChange={onChange}
                    employee={employee}
                    sites={sites}
                    departements={departements}
                    multiplePlanning={multiplePlanning}
                />
            ),
        },
    ];
    React.useEffect(() => {
        console.log(" the eemployee  = ", employee);
    }, [employee]);
    const [step, setStep] = React.useState(0);
    const [isDepartmentsExist, setIsDepartmentsExist] = React.useState(false);
    React.useEffect(() => {
        let isErrorWithDepatment = false;
        if (employee.belongsTo?.value?.length) {
            departements.map((siteDep: any) => {
                const { site, depts } = siteDep;
                if (
                    employee.belongsTo?.value.includes(site) &&
                    depts.length > 0
                ) {
                    let got = false;
                    employee.departements.value?.map((item: any) => {
                        if (item.site === site) {
                            got = true;
                        }
                    });
                    isErrorWithDepatment = !got;
                }
            });
        }
        setIsDepartmentsExist(isErrorWithDepatment);
    }, [departements]);
    const manageAtLeastOne = React.useMemo(() => {
        let doesManageAtLeastOne = true;
        if (
            employee.belongsTo?.value?.length &&
            employee.role.value === "Manager"
        ) {
            employee.belongsTo?.value?.map((siteId: any) => {
                if (employee.manage?.value?.length) {
                    let found = false;
                    for (const module of employee.manage?.value) {
                        if (module.site === siteId) {
                            found = true;
                            if (!module.roles?.length) {
                                doesManageAtLeastOne = false;
                            }
                        }
                    }
                } else doesManageAtLeastOne = false;
            });
        }
        return doesManageAtLeastOne;
    }, [employee.manage, employee.belongsTo, employee.role]);
    const nextStep = async () => {
        if (step === steps.length - 1) {
            // let stateToValidate: any = {
            //     pin: { ...employee.pin, touched: true },
            //     belongsTo: { ...employee.belongsTo, touched: true },
            //     email: { ...employee.email, touched: true },
            //     phone: { ...employee.phone, touched: true },
            //     fname: { ...employee.fname, touched: true },
            //     lname: { ...employee.lname, touched: true },
            // };
            // await validate(stateToValidate);
            let fields: string[] = [
                "pin",
                "belongsTo",
                "email",
                "phone",
                "fname",
                "lname",
            ];
            let ok = await checkError([...fields]);
            if (ok) return;
            ok = await checkError([]);
            if (ok) {
                Swal.fire({
                    title: "Un manager doit avoir accès à au moins une page",
                    icon: "error",
                });
                return;
            }
            await onSubmit();
            await onHide();
            setStep(0);
            return;
        } else {
            let fields: string[] = ["fname", "lname", "email", "phone"];
            let ok = await checkError([...fields]);
            if (ok) {
                return;
            }
        }
        setStep(step + 1);
    };

    const prevStep = () => {
        if (step > 0) {
            setStep(step - 1);
            return;
        }
        onHide();
    };
    React.useEffect(() => {
        if (
            employee.fname.hasError ||
            employee.lname.hasError ||
            employee.email.hasError ||
            employee.phone.hasError
        ) {
            setStep(0);
        }
    }, [employee]);
    return (
        <>
            <Helmet>
                <title>
                    {intl.formatMessage({ id: "APP.TITLE" })} |{" "}
                    {intl.formatMessage({ id: "MENU.EMPLOYEES.ADD_EMPLOYEE" })}
                </title>
            </Helmet>
            <Modal
                width={1000}
                height={700}
                show={show}
                onHide={onHide}
                title={intl.formatMessage({ id: "MENU.EMPLOYEE.ADD.TITLE" })}
                body={
                    <Stepper
                        steps={steps}
                        nextStep={nextStep}
                        prevStep={prevStep}
                        currentStep={step}
                    />
                }
                CancelComponent={
                    <Button
                        title={
                            step > 0
                                ? intl.formatMessage({ id: "MENU.PRED" })
                                : intl.formatMessage({ id: "MENU.CANCEL" })
                        }
                        leftComponent={
                            step > 0 ? (
                                <div className="rotate-180">
                                    <ArrowRight />
                                </div>
                            ) : (
                                <></>
                            )
                        }
                        onClick={prevStep}
                    />
                }
                ConfirmComponent={
                    <Button
                        variant="primary"
                        title={
                            step < steps.length - 1
                                ? intl.formatMessage({ id: "MENU.CONTINUE" })
                                : intl.formatMessage({ id: "MENU.SAVE" })
                        }
                        loading={confirmLoading}
                        rightComponent={
                            step < steps.length - 1 ? (
                                <div>
                                    <ArrowRight />
                                </div>
                            ) : (
                                <></>
                            )
                        }
                        disabled={
                            (step === 0 &&
                                (employee.fname.hasError ||
                                    !employee.fname.touched ||
                                    employee.lname.hasError ||
                                    !employee.lname.touched ||
                                    employee.email.hasError ||
                                    employee.phone.hasError)) ||
                            (step === 1 &&
                                (!manageAtLeastOne ||
                                    employee.pin.hasError ||
                                    !employee.pin.touched ||
                                    employee.belongsTo.hasError ||
                                    !employee.belongsTo.touched ||
                                    employee.fname.hasError ||
                                    employee.lname.hasError ||
                                    employee.email.hasError ||
                                    employee.phone.hasError ||
                                    employee.departements.hasError ||
                                    (!employee.departements.touched &&
                                        isDepartmentsExist)))
                        }
                        onClick={nextStep}
                    />
                }
            />
        </>
    );
}

interface EmployeeCreateInterface {
    sites: any[];
    show: boolean;
    onHide: () => void;
    employee: any;
    onChange: (field: string, value: any) => void;
    onBlur: (field: string) => void;
    onSubmit: () => void;
    checkError: (fields: string[]) => boolean;
    confirmLoading: boolean;
    departements: any[];
    multiplePlanning: boolean;
}
