import { useEmployees } from "../../../hooks/useEmployees";
import ProfileHeader from "../../../components/organism/ProfileHeader";

export default function ProfileHeaderPage({
    archiveAction,
    restoreAction,
}: ProfileHeaderPageInterface) {
    const { employeeForEdit } = useEmployees();

    return (
        <>
            {employeeForEdit ? (
                <ProfileHeader
                    id={employeeForEdit._id}
                    profilePic={employeeForEdit.picture}
                    reference={employeeForEdit.userMatricule}
                    firstName={employeeForEdit.fname}
                    lastName={employeeForEdit.lname}
                    role={employeeForEdit.role}
                    phone={employeeForEdit.phone}
                    email={employeeForEdit.email}
                    archived={employeeForEdit.deleted === true}
                    userScore={employeeForEdit.userScore}
                    archiveAction={() => {
                        archiveAction(employeeForEdit);
                    }}
                    restoreAction={() => {
                        restoreAction(employeeForEdit);
                    }}
                />
            ) : (
                <></>
            )}
        </>
    );
}

interface ProfileHeaderPageInterface {
    archiveAction(data: any): void;
    restoreAction(data: any): void;
}
