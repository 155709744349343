import * as React from "react";
import Modal from "../../atoms/Modal";
import Button from "../../atoms/Button";
import TextData from "../../atoms/TextData";
import PopUpCard from "../../molecules/PopUpCard";
import ZInput from "../../atoms/ZInput";
import ToggleButton from "../../atoms/Toggle";
import { useBranch } from "../../../hooks/useBranch";
import Swal from "sweetalert2";

const HeaderTitle = (props: any) => {
    const { isEdit } = props;
    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                margin: "25px auto 25px 40px",
            }}
        >
            <TextData
                variant="label"
                width="auto"
                size="22px"
                fontWeight="700"
                color="#2A346D"
            >
                {!isEdit ? "Ajouter " : "Modifier "}une condition
            </TextData>
        </div>
    );
};
export default function NewConditionPopup(props: any) {
    const { display, onClose, toEdit, useRepas, usePause } = props;
    const handleClose = () => {
        onClose && onClose();
    };
    const {
        setPauseRepasConditions,
        createPauseRepasCondition,
        deletePauseRepasCondition,
    } = useBranch();
    const [condition, setCondition] = React.useState({
        chreno: false,
        workHours: "",
        pause: "",
        repas: "",
        startHour: "",
        endHour: "",
    });

    React.useEffect(() => {
        if (!display) {
            setCondition({
                chreno: false,
                workHours: "",
                pause: "",
                repas: "",
                startHour: "",
                endHour: "",
            });
        }
    }, [display]);

    React.useEffect(() => {
        if (toEdit) {
            setCondition((prev: any) => {
                let objectToUpdate: any = {
                    chreno: toEdit.chreno,
                };
                if (usePause) {
                    objectToUpdate.pause = Number(toEdit.pause);
                }
                if (useRepas) {
                    objectToUpdate.repas = Number(toEdit.repas);
                }
                if (toEdit.chreno) {
                    objectToUpdate.startHour = toEdit.startHour;
                    objectToUpdate.endHour = toEdit.endHour;
                } else {
                    objectToUpdate.workHours = toEdit.workHours;
                }
                return objectToUpdate;
            });
        } else {
            setCondition({
                chreno: false,
                workHours: "",
                pause: "",
                repas: "",
                startHour: "",
                endHour: "",
            });
        }
    }, [toEdit]);
    const handleAddCondition = async () => {
        let objectToSend: any = {
            chreno: condition.chreno,
            id: null,
        };
        if (usePause) {
            objectToSend.pause = Number(condition.pause);
        }
        if (useRepas) {
            objectToSend.repas = Number(condition.repas);
        }
        if (condition.chreno) {
            objectToSend.startHour = condition.startHour;
            objectToSend.endHour = condition.endHour;
        } else {
            objectToSend.workHours = Number(condition.workHours);
        }
        let res: any = await createPauseRepasCondition(objectToSend);
        if (res) {
            handleClose();
            await setPauseRepasConditions();
        }
    };
    const handleEditCondition = async () => {
        let objectToSend: any = {
            chreno: condition.chreno,
            pause: Number(condition.pause),
            repas: Number(condition.repas),
            id: toEdit._id,
        };
        if (condition.chreno) {
            objectToSend.startHour = condition.startHour;
            objectToSend.endHour = condition.endHour;
        } else {
            objectToSend.workHours = Number(condition.workHours);
        }
        let res: any = await createPauseRepasCondition(objectToSend);
        if (res) {
            handleClose();
            await setPauseRepasConditions();
        }
    };
    const handleDeleteCondition = async () => {
        await Swal.fire({
            title: "supprimer cette condition",
            text: "Voulez vous supprimer cette condition?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Oui, supprime-le!",
            cancelButtonText: "Annuler",
        }).then(async (result) => {
            if (result.isConfirmed) {
                const res: any = await deletePauseRepasCondition(toEdit._id);
                if (res) {
                    handleClose();
                    Swal.fire(
                        "Supprimé!",
                        "la condition a été supprimée.",
                        "success",
                    );
                    setPauseRepasConditions();
                } else {
                    Swal.fire({
                        title: "Erreur!",
                        text: "Une erreur au niveau serveur!",
                        icon: "error",
                        confirmButtonText: "Ok",
                    });
                }
            }
        });
    };
    return (
        <Modal modalEnabled={display} onClose={handleClose}>
            <div className="adding-note-container">
                <PopUpCard
                    containerWidth="500px"
                    display={display}
                    onClose={onClose}
                    header={<HeaderTitle isEdit={!!toEdit} />}
                    widthCloseIcon="24px"
                    styleCloseIcon={{
                        height: "auto",
                        marginRight: "37px",
                        width: "24px",
                        border: "1px solid #edeff533",
                        borderTopRightRadius: "20px",
                    }}
                    headerStyle={{
                        fontSize: "22px",
                        fontWeight: "700",
                        color: "#2A346D",
                    }}
                    popupHeaderStyle={{
                        height: "80px",
                        backgroundColor: "#edeff54c",
                        borderBottom: "1px solid #C7CBE2",
                    }}
                    button1Text="Annuler"
                    onClickButton1={handleClose}
                    button2Text={toEdit ? "Supprimer" : "Ajouter"}
                    onClickButton2={
                        toEdit ? handleDeleteCondition : handleAddCondition
                    }
                    button2Style={
                        toEdit
                            ? {
                                  backgroundColor: "#eb2f06",
                                  color: "white",
                                  fontSize: "14px",
                              }
                            : undefined
                    }
                    button3Text={toEdit ? "Modifier" : undefined}
                    onClickButton3={toEdit ? handleEditCondition : undefined}
                >
                    <div
                        style={{
                            padding: "20px 30px",
                            display: "flex",
                            flexDirection: "column",
                            gap: "20px",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            <div>Créneau horaire</div>
                            <ToggleButton
                                isChecked={condition.chreno}
                                onClick={() =>
                                    setCondition((prev: any) => ({
                                        ...prev,
                                        chreno: !prev.chreno,
                                    }))
                                }
                            />
                        </div>
                        {condition.chreno ? (
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "10px",
                                }}
                            >
                                <div
                                    style={{
                                        color: "#0f556d",
                                        fontSize: "16px",
                                        fontWeight: "bold",
                                    }}
                                >
                                    Si l’employé travaille pendant ce créneau
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        gap: "10px",
                                    }}
                                >
                                    <ZInput
                                        type="time"
                                        required={false}
                                        label="Heure début"
                                        field={{
                                            name: "startHour",
                                            value: condition.startHour,
                                        }}
                                        onUpdate={(value) => {
                                            setCondition((prev: any) => ({
                                                ...prev,
                                                startHour: value,
                                            }));
                                        }}
                                    />
                                    <ZInput
                                        type="time"
                                        required={false}
                                        label="Heure fin"
                                        field={{
                                            name: "endHour",
                                            value: condition.endHour,
                                        }}
                                        onUpdate={(value) => {
                                            setCondition((prev: any) => ({
                                                ...prev,
                                                endHour: value,
                                            }));
                                        }}
                                    />
                                </div>
                            </div>
                        ) : (
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "10px",
                                }}
                            >
                                <div
                                    style={{
                                        color: "#0f556d",
                                        fontSize: "16px",
                                        fontWeight: "bold",
                                    }}
                                >
                                    Si l'employé dépasse x heures de travail
                                    dans la journée
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        gap: "10px",
                                    }}
                                >
                                    <ZInput
                                        type="text"
                                        required={false}
                                        label="Nombre d'heures"
                                        field={{
                                            name: "workHours",
                                            value: condition.workHours,
                                        }}
                                        onUpdate={(value) => {
                                            if (
                                                !value ||
                                                (Number(value) >= 0 &&
                                                    Number(value) <= 24 &&
                                                    value !== "00")
                                            ) {
                                                setCondition((prev: any) => ({
                                                    ...prev,
                                                    workHours: value,
                                                }));
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        )}
                        <div>
                            <div
                                style={{
                                    color: "#0f556d",
                                    fontSize: "16px",
                                    fontWeight: "bold",
                                }}
                            >
                                On lui accorde automatiquement
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "10px",
                                }}
                            >
                                {usePause && (
                                    <ZInput
                                        type="text"
                                        required={false}
                                        field={{
                                            name: "",
                                            value: condition.pause,
                                        }}
                                        label="Pause"
                                        onUpdate={(value) => {
                                            if (
                                                !value ||
                                                (Number(value) >= 0 &&
                                                    Number(value) <= 999 &&
                                                    value !== "00")
                                            ) {
                                                setCondition((prev: any) => ({
                                                    ...prev,
                                                    pause: value,
                                                }));
                                            }
                                        }}
                                    />
                                )}
                                {useRepas && (
                                    <ZInput
                                        type="text"
                                        required={false}
                                        field={{
                                            name: "",
                                            value: condition.repas,
                                        }}
                                        label="Repas"
                                        onUpdate={(value) => {
                                            if (
                                                !value ||
                                                (Number(value) >= 0 &&
                                                    Number(value) <= 9)
                                            ) {
                                                setCondition((prev: any) => ({
                                                    ...prev,
                                                    repas: value,
                                                }));
                                            }
                                        }}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </PopUpCard>
            </div>
        </Modal>
    );
}
