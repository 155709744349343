import * as React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { ROUTER } from "../constants/env";
import { useLogin } from "../hooks/useLogin";

export default function RequireAuth({ children }: RequireAuthProps) {
    const { auth, checkMe, user } = useLogin();
    const location = useLocation();

    React.useEffect(() => {
        checkMe();
    }, [location.pathname]);

    if (!user) {
        return <Navigate to={ROUTER.AUTH.LOGIN} />;
    }

    return children;
}

interface RequireAuthProps {
    children: JSX.Element;
}
