import React, { Suspense } from "react";
import { useDrop } from "react-dnd";
import "./style.scss";
import TextData from "../../atoms/TextData";
import CellEmpty from "../../molecules/CellEmpty";
import { ReactComponent as CloseSVG } from "./close.svg";
import { ReactComponent as AttentionSVG } from "./attention.svg";
import { ReactComponent as MsgSVG } from "./Msg.svg";
import { ReactComponent as SpoonSVG } from "./spoon.svg";
import { ReactComponent as DangerSVG } from "./danger.svg";
import { ReactComponent as TimeSVG } from "./time.svg";
import { ReactComponent as IndispoSVG } from "./no-work.svg";
import { ReactComponent as CongeSVG } from "./conge.svg";
// import AttendanceCard from "../../organism/AttendanceCard";
import { fomatStartEndTime, formatTime } from "../../../_helpers/Functions";
import { usePlanning } from "../../../hooks/usePlanning";
import moment from "moment";
import { useBranch } from "../../../hooks/useBranch";
import { direction } from "html2canvas/dist/types/css/property-descriptors/direction";
import Shift from "./Shift";
import Loading from "../../atoms/Loading";
const congeTypes = [
    { label: "Arrêt maladie", value: "arret_maladie" },
    { label: "Congé maternité", value: "conge_maternite" },
    { label: "Congé paternité", value: "conge_paternite" },
    { label: "Congé payé", value: "paid_leave" },
    { label: "Congé sans solde", value: "unpaid_leave" },
    { label: "Évènement familial", value: "family_event" },
];
const AttendanceCard = React.lazy(
    () => import("../../organism/AttendanceCard"),
);
function PlanningCell(props: any /*PlanningCellProps*/) {
    const {
        configuration,
        isEmpty,
        alert,
        alertText,
        data,
        defaultEmployee,
        day,
        dayOrder,
        disponibility,
        published,
        department,
        conge,
    } = props;
    const {
        copyShift,
        planningOptions,
        draggedShiftType,
        planningInfoDepartments,
    } = usePlanning();
    const [isDraggingOver, setIsDraggingOver] = React.useState(false);
    const [isDraggingOverCopy, setIsDraggingOverCopy] = React.useState(false);
    const [isDraggingOverMove, setIsDraggingOverMove] = React.useState(false);
    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setIsDraggingOver(true); // Set dragging over state
    };
    const handleDragLeave = (event: any) => {
        event.preventDefault();
        if (!event.currentTarget.contains(event.relatedTarget)) {
            setIsDraggingOver(false);
        }
        // Reset dragging over state
    };
    const handleDragOverCopy = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setIsDraggingOverCopy(true); // Set dragging over state
    };
    const handleDragLeaveCopy = () => {
        setIsDraggingOverCopy(false); // Reset dragging over state
    };
    const handleDragOverMove = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setIsDraggingOverMove(true); // Set dragging over state
    };
    const handleDragLeaveMove = () => {
        setIsDraggingOverMove(false); // Reset dragging over state
    };
    const handleDropCopy = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        const shiftData = JSON.parse(event.dataTransfer.getData("text/plain"));
        const { shift, type } = shiftData;
        if (shift && type) {
            copyShift(
                {
                    shiftId: shift._id,
                    employeeId: defaultEmployee?._id,
                    day,
                    department: department?._id || null,
                },
                "copy",
            );
        }
        setIsDraggingOverCopy(false);
        setIsDraggingOver(false);
    };
    const handleDropMove = async (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        const shiftData = JSON.parse(event.dataTransfer.getData("text/plain"));
        const { shift, type } = shiftData;
        if (shift && type) {
            copyShift(
                {
                    shiftId: shift?._id,
                    employeeId: defaultEmployee?._id,
                    day,
                    department: department?._id || null,
                },
                "move",
            );
        }
        setIsDraggingOverMove(false);
        setIsDraggingOver(false);
    };
    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setIsDraggingOver(false);
    };
    const [isShiftEditing, setIsShiftEditing] = React.useState(false);
    const [isShift, setIsShift] = React.useState(true);
    const [toEdit, setShiftToEdit] = React.useState<any>(null);
    const [isClicked, setIsClicked] = React.useState(false);
    const [alertsList, setAlertsList] = React.useState<String[]>([]);
    const [shifts, setShifts] = React.useState<any>([]);
    React.useEffect(() => {
        // setShifts(data);
        if (data?.length > 0) {
            let alerts: Set<string> = new Set();
            for (let i = 0; i < data.length; i++) {
                if (data[i].collectiveAgreementViolations?.length > 0) {
                    for (
                        let j = 0;
                        j < data[i].collectiveAgreementViolations?.length;
                        j++
                    ) {
                        alerts.add(
                            issueDescription(
                                Number(
                                    data[i].collectiveAgreementViolations[j]
                                        .issueCode,
                                ),
                            ),
                        );
                    }
                }
            }
            setAlertsList(Array.from(alerts));
        } else {
            setAlertsList([]);
        }
    }, [data]);
    const [isCellEmpty, setIsCellEmpty] = React.useState(isEmpty);
    React.useEffect(() => {
        setIsCellEmpty(isEmpty);
    }, [isEmpty]);
    const [isHover, setIsHover] = React.useState(false);
    // Drag & Drop events
    const [{ isOver }, drop] = useDrop(() => ({
        accept: "shift",
        drop: (item) => addToPlanningCell(item),
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
        }),
    }));
    const addToPlanningCell = (item: any) => {
        setShifts((prevShifts: any) => [...prevShifts, { id: item.id }]);
        setIsCellEmpty(false);
    };
    // Drag & Drop events
    React.useEffect(() => {
        if (toEdit) console.log(" toEdit = = =", toEdit);
    }, [toEdit]);
    if (configuration === "readonly") {
        return (
            <div
                className="planning-cell-container"
                // onDrop={handleDrop}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                style={{
                    height: isCellEmpty ? "100%" : "fit-content",
                }}
            >
                {!isCellEmpty && (
                    <>
                        {data?.map((shift: any, key: any) => {
                            if (
                                shift.type === 1 ||
                                planningOptions.hideAbsences === false
                            ) {
                                return (
                                    <Shift
                                        key={shift._id}
                                        data={shift}
                                        setIsShiftEditing={setIsShiftEditing}
                                        setShiftToEdit={setShiftToEdit}
                                        setIsShift={setIsShift}
                                        setIsClicked={setIsClicked}
                                        published={published}
                                        extern={
                                            planningInfoDepartments?.[
                                                shift.department
                                            ]?.department?.name &&
                                            ((department &&
                                                department?._id !==
                                                    shift.department) ||
                                                (!department &&
                                                    shift.department !== null))
                                        }
                                    />
                                );
                            } else return null;
                        })}

                        <div className="rest-planning">
                            {alertsList?.length > 0 &&
                            planningOptions.hideAlerts === false ? (
                                <div
                                    className="alert-label"
                                    style={{ position: "relative" }}
                                >
                                    <AttentionSVG
                                        color="#fb3640"
                                        width={12}
                                        height={12}
                                    />
                                    <TextData
                                        variant="label"
                                        width="auto"
                                        size="11px"
                                        fontWeight="500"
                                        style={{
                                            textTransform: "uppercase",
                                            margin: 2,
                                            marginLeft: 3,
                                        }}
                                        color="#fb3640"
                                    >
                                        {alertText}
                                    </TextData>
                                    <TextData
                                        variant="label"
                                        className={["alert-showing"]}
                                        width="150px"
                                        size="11px"
                                        fontWeight="500"
                                        color="#FB3640"
                                    >
                                        {alertsList.map((item, index) => (
                                            <div>
                                                {index + 1}
                                                {"- "}
                                                {item}
                                            </div>
                                        ))}
                                    </TextData>
                                </div>
                            ) : null}
                        </div>
                    </>
                )}
            </div>
        );
    } else {
        // console.log(" dattttaaa plannn = ", data);
        return (
            <div
                className="planning-cell-container"
                // onMouseEnter={() => (isCellEmpty ? setIsHover(true) : () => {})}
                // onDragEnter={() => (isCellEmpty ? setIsHover(true) : () => {})}
                // ref={drop}
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                style={{
                    height: "100%",
                    // height: "100%",
                    // minHeight: "65px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "center",
                }}
            >
                {isCellEmpty ? (
                    <>
                        {/* {isDraggingOver && !published && ( */}
                        <div
                            className="drop-indicator"
                            style={{
                                width: "100%",
                                display:
                                    isDraggingOver && !published
                                        ? "block"
                                        : "none",
                            }}
                        >
                            <div
                                onDragOver={handleDragOverCopy}
                                onDragLeave={handleDragLeaveCopy}
                                onDrop={handleDropCopy}
                                style={{
                                    padding: "10px",
                                    borderRadius: "5px",
                                    border: "1px dashed #6f78ab",
                                    width: "100%",
                                    marginBottom: "2px",
                                    color: isDraggingOverCopy
                                        ? "white"
                                        : "#6f78ab",
                                    backgroundColor: isDraggingOverCopy
                                        ? "#2a8bab"
                                        : "",
                                }}
                            >
                                Copier
                            </div>
                            {draggedShiftType === "normal" && (
                                <div
                                    onDragOver={handleDragOverMove}
                                    onDragLeave={handleDragLeaveMove}
                                    onDrop={handleDropMove}
                                    style={{
                                        padding: "10px",
                                        borderRadius: "5px",
                                        border: "1px dashed #6f78ab",
                                        width: "100%",
                                        color: isDraggingOverMove
                                            ? "white"
                                            : "#6f78ab",
                                        backgroundColor: isDraggingOverMove
                                            ? "#2a8bab"
                                            : "",
                                    }}
                                >
                                    Déplacer
                                </div>
                            )}
                        </div>
                        {/* )} */}
                        <CellEmpty
                            setIsClicked={setIsClicked}
                            published={published}
                            isWeek={true}
                        />
                        {/* <button
                            style={{
                                backgroundColor: "yellow",
                                width: "100%",
                                height: "85px",
                            }}
                        >
                            add
                        </button> */}
                    </>
                ) : (
                    <div
                        // onMouseEnter={() => setIsHover(true)}
                        // onDragEnter={() => setIsHover(true)}
                        // onMouseLeave={() => setIsHover(false)}
                        // onDragLeave={() => setIsHover(false)}
                        style={{
                            width: "100%",
                        }}
                    >
                        {data?.map((shift: any, key: any) => {
                            if (
                                shift.type === 1 ||
                                planningOptions.hideAbsences === false
                            ) {
                                return (
                                    <Shift
                                        key={shift._id}
                                        data={shift}
                                        setIsShiftEditing={setIsShiftEditing}
                                        setShiftToEdit={setShiftToEdit}
                                        setIsShift={setIsShift}
                                        setIsClicked={setIsClicked}
                                        published={published}
                                        department={department}
                                        extern={
                                            planningInfoDepartments?.[
                                                shift.department
                                            ]?.department?.name &&
                                            shift.type === 1 &&
                                            ((department &&
                                                department?._id !==
                                                    shift.department) ||
                                                (!department &&
                                                    shift.department !== null))
                                        }
                                    />
                                );
                            }
                        })}
                        {disponibility && (
                            <div
                                className="indisponibility-cell"
                                style={{
                                    borderColor: "#1D1B91",
                                    backgroundColor: "#817FDD",
                                }}
                            >
                                <div className="icon">
                                    <IndispoSVG
                                        color="#1D1B91"
                                        width={20}
                                        height={20}
                                    />
                                </div>
                                <div
                                    className="text"
                                    style={{
                                        color: "#1D1B91",
                                    }}
                                >
                                    <div
                                        style={{
                                            fontWeight: "bold",
                                        }}
                                    >
                                        Indisponible
                                    </div>
                                    <div>
                                        de {disponibility?.startHour} à{" "}
                                        {disponibility?.endHour}
                                    </div>
                                </div>
                            </div>
                        )}
                        {conge && (
                            <div
                                className="indisponibility-cell"
                                style={{
                                    borderColor: "#0F556D",
                                    backgroundColor: "#7EC3DB",
                                }}
                            >
                                <div className="icon">
                                    <CongeSVG
                                        color="#0F556D"
                                        width={20}
                                        height={20}
                                    />
                                </div>
                                <div
                                    className="text"
                                    style={{
                                        color: "#0F556D",
                                    }}
                                >
                                    <div
                                        style={{
                                            fontWeight: "bold",
                                        }}
                                    >
                                        {
                                            congeTypes?.find(
                                                (congeType: any) =>
                                                    congeType.value ===
                                                    conge?.type,
                                            )?.label
                                        }
                                    </div>
                                    <div>Toute la journée</div>
                                </div>
                            </div>
                        )}
                        {/* {isDraggingOver && !published && ( */}
                        <div
                            className="drop-indicator"
                            style={{
                                width: "100%",
                                display:
                                    isDraggingOver && !published
                                        ? "block"
                                        : "none",
                            }}
                        >
                            <div
                                onDragOver={handleDragOverCopy}
                                onDragLeave={handleDragLeaveCopy}
                                onDrop={handleDropCopy}
                                style={{
                                    padding: "10px",
                                    borderRadius: "5px",
                                    border: "1px dashed #6f78ab",
                                    width: "100%",
                                    marginBottom: "2px",
                                    color: isDraggingOverCopy
                                        ? "white"
                                        : "#6f78ab",
                                    backgroundColor: isDraggingOverCopy
                                        ? "#2a8bab"
                                        : "",
                                }}
                            >
                                Copier
                            </div>
                            {draggedShiftType === "normal" && (
                                <div
                                    onDragOver={handleDragOverMove}
                                    onDragLeave={handleDragLeaveMove}
                                    onDrop={handleDropMove}
                                    style={{
                                        padding: "10px",
                                        borderRadius: "5px",
                                        border: "1px dashed #6f78ab",
                                        width: "100%",
                                        color: isDraggingOverMove
                                            ? "white"
                                            : "#6f78ab",
                                        backgroundColor: isDraggingOverMove
                                            ? "#2a8bab"
                                            : "",
                                    }}
                                >
                                    Déplacer
                                </div>
                            )}
                        </div>
                        {/* )} */}
                        <div className="rest-planning">
                            <CellEmpty
                                setIsClicked={setIsClicked}
                                published={published}
                                isWeek={true}
                                withoutText={true}
                            />
                            {alertsList?.length > 0 &&
                                planningOptions.hideAlerts === false && (
                                    <div
                                        className="alert-label"
                                        style={{ position: "relative" }}
                                    >
                                        <AttentionSVG
                                            color="#fb3640"
                                            width={12}
                                            height={12}
                                        />
                                        <TextData
                                            variant="label"
                                            width="auto"
                                            size="11px"
                                            fontWeight="500"
                                            style={{
                                                textTransform: "uppercase",
                                                margin: 2,
                                                marginLeft: 3,
                                            }}
                                            color="#fb3640"
                                        >
                                            {alertText}
                                        </TextData>
                                        <TextData
                                            variant="div"
                                            className={["alert-showing"]}
                                            width="max-content"
                                            size="11px"
                                            fontWeight="500"
                                            color="#FB3640"
                                        >
                                            {alertsList.map((item, index) => (
                                                <div
                                                    style={{
                                                        textAlign: "left",
                                                    }}
                                                >
                                                    {index + 1}
                                                    {"- "}
                                                    {item}
                                                </div>
                                            ))}
                                        </TextData>
                                    </div>
                                )}
                        </div>
                    </div>
                )}
                {isClicked && (
                    <Suspense fallback={<></>}>
                        <AttendanceCard
                            display={isClicked}
                            onClose={() => {
                                setIsClicked(false);
                                setIsShift(true);
                                setShiftToEdit(null);
                                setIsShiftEditing(false);
                            }}
                            defaultEmployee={defaultEmployee}
                            day={day}
                            dayOrder={dayOrder}
                            isShiftEditing={isShiftEditing}
                            setIsShiftEditing={setIsShiftEditing}
                            isShift={isShift}
                            setIsShift={setIsShift}
                            toEdit={toEdit}
                            setShiftToEdit={setShiftToEdit}
                            department={department}
                        />
                    </Suspense>
                )}
            </div>
        );
    }
}
const ISSUE_CODE = {
    MAX_DAILY_WORK_HOURS_VIOLATION: 3,
    MAX_CONSEC_WORK_HOURS_WITHOUT_REST_VIOLATION: 5,
    MIN_CONSEC_REST_HOURS_VIOLATION: 0,
    MAX_CONSEC_WORK_DAYS_VIOLATION: 2,
    MAX_WEEKLY_WORK_HOURS_VIOLATION: 1,
    BREAK_WHEN_MAX_HOURS_REACHED_VIOLATION: 4,
    OVERTIME_DETAILS_VIOLATION: 6,
};
//english alerts

// const issueDescription = (issueCode: number) => {
//     switch (issueCode) {
//         case ISSUE_CODE.MIN_CONSEC_REST_HOURS_VIOLATION:
//             return "Minimum consecutive rest hours violated";
//         case ISSUE_CODE.MAX_WEEKLY_WORK_HOURS_VIOLATION:
//             return "Maximum weekly work hours exceeded";
//         case ISSUE_CODE.MAX_CONSEC_WORK_DAYS_VIOLATION:
//             return "Maximum consecutive work days violated";
//         case ISSUE_CODE.MAX_DAILY_WORK_HOURS_VIOLATION:
//             return "Maximum daily work hours exceeded";
//         case ISSUE_CODE.BREAK_WHEN_MAX_HOURS_REACHED_VIOLATION:
//             return "Break required when maximum work hours reached";
//         case ISSUE_CODE.MAX_CONSEC_WORK_HOURS_WITHOUT_REST_VIOLATION:
//             return "Maximum consecutive work hours without rest exceeded";
//         case ISSUE_CODE.OVERTIME_DETAILS_VIOLATION:
//             return "Overtime details violation"; // Customize this as needed
//         default:
//             return "Unknown issue";
//     }
// };

//french alerts

const issueDescription = (issueCode: number) => {
    switch (issueCode) {
        case ISSUE_CODE.MIN_CONSEC_REST_HOURS_VIOLATION:
            return "Violation du nombre minimum d'heures de repos consécutives";
        case ISSUE_CODE.MAX_WEEKLY_WORK_HOURS_VIOLATION:
            return "Dépassement du nombre maximum d'heures de travail hebdomadaires";
        case ISSUE_CODE.MAX_CONSEC_WORK_DAYS_VIOLATION:
            return "Violation du nombre maximum de jours de travail consécutifs";
        case ISSUE_CODE.MAX_DAILY_WORK_HOURS_VIOLATION:
            return "Dépassement du nombre maximum d'heures de travail quotidiennes";
        case ISSUE_CODE.BREAK_WHEN_MAX_HOURS_REACHED_VIOLATION:
            return "Pause requise lorsque le nombre maximum d'heures de travail est atteint";
        case ISSUE_CODE.MAX_CONSEC_WORK_HOURS_WITHOUT_REST_VIOLATION:
            return "Dépassement du nombre maximum d'heures de travail consécutives sans repos";
        case ISSUE_CODE.OVERTIME_DETAILS_VIOLATION:
            return "Violation des détails des heures supplémentaires";
        // Customize this as needed
        default:
            return "Problème inconnu";
    }
};
export default React.memo(PlanningCell);

interface PlanningCellProps {
    configuration?: "readonly" | "edit";
    isEmpty?: boolean;
    alert?: boolean;
    alertText?: string;
    data?: any;
    planningEmployees: any;
    defaultEmployee?: any;
    day: any;
    dayOrder?: number;
    disponibility?: any;
}

PlanningCell.defaultProps = {
    configuration: "edit",
    isEmpty: false,
    alert: false,
    alertText: "ALERT",
    dayOrder: 0,
};
