import React from "react";
import AttendanceCard from "../../organism/AttendanceCard";
import "./style.css";
import Shift from "./Shift";
import { usePlanning } from "../../../hooks/usePlanning";

export default function PlanningDayCell({
    data,
    defaultEmployee,
    published,
    department,
    setIsShift,
    setIsClicked,
    setIsShiftEditing,
    setShiftToEdit,
}: any) {
    const { planningInfoDepartments } = usePlanning();
    return (
        <>
            {/* hhii
            {planningInfoDepartments[data.department]?.department?.name} */}
            <Shift
                // key={key}
                data={data}
                setIsShiftEditing={setIsShiftEditing}
                setShiftToEdit={setShiftToEdit}
                setIsShift={setIsShift}
                setIsClicked={setIsClicked}
                extern={
                    planningInfoDepartments?.[data.department]?.department
                        ?.name &&
                    ((department && department?._id !== data.department) ||
                        (!department && data.department !== null))
                }
            />
        </>
    );
}
