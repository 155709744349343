import React from "react";
import Sidebar from "../../molecules/Sidebar";
import TabNavigator from "../../molecules/TabNavigator";
import TabContent from "../../atoms/TabContent";
import { useIntl } from "react-intl";
import { Helmet } from "react-helmet";
import { User, CalendarX, Document, LockKey, Phone2 } from "../../../Icons";

const TabNavigatorFormatter = ({
    text,
    icon,
    activeIcon,
    active,
}: TabNavigatorFormatterInterface) => {
    return (
        <div
            className={`flex gap-2 items-center ${
                active ? "text-t-500" : "text-n-500"
            } group-hover:text-t-500`}
        >
            {icon}
            <span className={`text-base font-bold`}>{text}</span>
        </div>
    );
};

export default function EmployeesEdit({
    GeneralInfo,
    HRFolder,
    Unavailability,
    Pointeuse,
    ChangePassword,
    ProfileHeader,
}: EmployeesEditInterface) {
    const intl = useIntl();
    const [activeTab, setActiveTab] = React.useState(0);

    const onMenuChange = (tab: number) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    const tabs = [
        {
            title: intl.formatMessage({ id: "EMPLOYEE.EDIT.GENERAL_INFOS" }),
            data: {
                text: intl.formatMessage({ id: "EMPLOYEE.EDIT.GENERAL_INFOS" }),
                icon: <User />,
                active: activeTab === 0,
            },
        },
        {
            title: intl.formatMessage({ id: "EMPLOYEE.EDIT.HR_FOLDER" }),
            data: {
                text: intl.formatMessage({ id: "EMPLOYEE.EDIT.HR_FOLDER" }),
                icon: <Document />,
                active: activeTab === 1,
            },
        },
        // {
        //     title: intl.formatMessage({ id: "EMPLOYEE.EDIT.UNAVAILABILITY" }),
        //     data: {
        //         text: intl.formatMessage({
        //             id: "EMPLOYEE.EDIT.UNAVAILABILITY",
        //         }),
        //         icon: <CalendarX />,
        //         active: activeTab === 2,
        //     },
        // },
        /*{
    {
      title: intl.formatMessage({ id: "EMPLOYEE.EDIT.POINTEUSE" }),
      data: {
        text: intl.formatMessage({ id: "EMPLOYEE.EDIT.POINTEUSE" }),
        icon: <Phone2 />,
        active: activeTab === 3,
      },
    },
  }*/
        {
            title: intl.formatMessage({ id: "EMPLOYEE.EDIT.CHANGE_PASSWORD" }),
            data: {
                text: intl.formatMessage({
                    id: "EMPLOYEE.EDIT.CHANGE_PASSWORD",
                }),
                icon: <LockKey />,
                active: activeTab === 3,
            },
        },
    ];

    return (
        <>
            <Helmet>
                <title>
                    {intl.formatMessage({ id: "APP.TITLE" })} |{" "}
                    {intl.formatMessage({ id: "MENU.EMPLOYEE.EDIT" })}
                </title>
            </Helmet>

            <div className="w-full">
                {/* Header */}
                <div className="w-full rounded-xl _shadow mb-10 bg-white">
                    {ProfileHeader}
                </div>
                <TabNavigator
                    tabs={tabs.map((tab, index) => ({
                        ...tab,
                        active: index === activeTab,
                        onClick: onMenuChange,
                        formatter: (data: TabNavigatorFormatterInterface) => (
                            <TabNavigatorFormatter {...data} />
                        ),
                    }))}
                    containerClasses={"!bg-none"}
                    headerContainerClasses={"!mx-2"}
                    headerClasses="group"
                >
                    <TabContent
                        containerClasses="bg-white rounded-lg _shadow px-10 py-5  " //overflow-scroll"
                        value={activeTab}
                        index={0}
                        component={GeneralInfo}
                        containerCss={{
                            maxHeight: "calc(60vh - 30px)",
                            // overflow: "auto",
                        }}
                    />
                    <TabContent
                        containerClasses="bg-white rounded-lg _shadow px-10 overflow-scroll"
                        value={activeTab}
                        index={1}
                        component={HRFolder}
                        containerCss={{
                            maxHeight: "calc(60vh - 30px)",
                            // overflow: "auto",
                        }}
                    />
                    {/* <TabContent
                        containerClasses="bg-white rounded-lg _shadow px-10 py-5  overflow-scroll"
                        value={activeTab}
                        index={2}
                        component={Unavailability}
                        containerCss={{ maxHeight: "60vh", overflow: "auto" }}
                    /> */}
                    {/* 
            <TabContent
              containerClasses="bg-white rounded-lg _shadow px-10 py-5"
              value={activeTab}
              index={3}
              component={Pointeuse}
            />*/}
                    <TabContent
                        containerClasses="bg-white rounded-lg _shadow px-10 py-5  overflow-scroll"
                        value={activeTab}
                        index={2}
                        component={ChangePassword}
                        containerCss={{
                            maxHeight: "calc(60vh - 30px)",
                            // overflow: "auto",
                        }}
                    />
                </TabNavigator>
            </div>
        </>
    );
}

interface EmployeesEditInterface {
    ProfileHeader: React.ReactNode;
    GeneralInfo: React.ReactNode;
    HRFolder: React.ReactNode;
    Unavailability: React.ReactNode;
    Pointeuse: React.ReactNode;
    ChangePassword: React.ReactNode;
}

interface TabNavigatorFormatterInterface {
    text: string;
    active: boolean;
    icon: string;
    activeIcon: string;
}
