import React from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
// import { Zendesk } from "@rathpc/zendesk-react";
import ReactDOM from "react-dom/client";
import { RouterProvider as Router } from "react-router-dom";
import { Provider } from "react-redux";
import { router } from "./routers";
import store from "./redux/store";
import ESPIntlProvider from "./config/ESPIntlProvider";
import NotificationProvider from "./Context/Notification";
import "./index.css";
import "./tailwind.css";
import moment from "moment";
import "moment/locale/fr";
import Hotjar from "@hotjar/browser";
import { ZENDESK_KEY } from "./constants/env";
import * as Sentry from "@sentry/react";
import { sentryEnv } from "./constants/envFile";

moment.locale("fr");
if (sentryEnv !== "disabled") {
    Sentry.init({
        dsn: "https://1c431dd293e37ef0bf93412d057c0585@o4506535068893184.ingest.us.sentry.io/4507261273767936",
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration(),
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["https://app.esperoo.fr/"],
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        environment: sentryEnv,
    });
}

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement,
);

// const siteId = 4953342;
// const hotjarVersion = 6;

// Hotjar.init(siteId, hotjarVersion);

// const identifyCustomer = () => {
//     const userStorage = sessionStorage.getItem("user");
//     const selectedBranchStorage = sessionStorage.getItem("selectedBranch");
//     if (userStorage) {
//         let selectedBranch = null;
//         if (selectedBranchStorage) {
//             selectedBranch = JSON.parse(selectedBranchStorage);
//         }
//         const customer = JSON.parse(userStorage);
//         Hotjar.identify(customer._id, {
//             email: customer.email,
//             role: customer.role,
//             branchID: selectedBranch ? selectedBranch._id : "",
//             branchName: selectedBranch ? selectedBranch.name : "",
//             branchLicense: selectedBranch ? selectedBranch.license : "",
//         });
//     }
// };

// identifyCustomer();
const initCallback = () => {
    if (window.zE) {
        window.zE("webWidget", "hide");
    }
};
const zendeskSettings = {
    // color: { theme: "#ff0000" },
    contactForm: {
        suppress: false,
    },
    contactOptions: {
        enabled: true,
        contactButton: { "*": "Contactez nous" },
    },
    position: {
        horizontal: "right",
        vertical: "bottom",
    },
    // zIndex: -1,
};
root.render(
    // <React.StrictMode>
    <>
        <ESPIntlProvider>
            <NotificationProvider>
                <Provider store={store}>
                    <DndProvider backend={HTML5Backend}>
                        <Router router={router}></Router>
                    </DndProvider>
                </Provider>
            </NotificationProvider>
        </ESPIntlProvider>

    </>,
    // </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
