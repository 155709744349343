import { useAppDispatch, useAppSelector } from "./useReduxHooks";
import { ROUTER } from "../constants/env";
import { API } from "../constants/env";

import {
    exportClassicReportService,
    exportDelayReportService,
    generateAttendancePDFService,
    getCustomSortService,
    getEmployeeFullReportService,
    getMonthlyWorkHoursService,
    getWeeklyWorkHoursService,
} from "../redux/features/reports/service";
import {
    clearSelectedShift,
    updateReport,
    setReport,
    setWorkHoursDateRange,
    setBranchs,
    setSelectedBranchs,
    setReportRangeType,
    setDataLength,
} from "../redux/features/reports/reportSlice";
import Swal from "sweetalert2";
import { EmployeeState } from "../redux/features/reports/types";
import { getWeeksInMonth, getWeeksBetweenDates } from "../_helpers/Functions";
import { getEmployeeWorkHoursService } from "../redux/features/employeeWorkHours/service";
import moment from "moment";

export const useReport = () => {
    const dispatch = useAppDispatch();
    const report = useAppSelector((state: any) => state.reports);
    const { branchs, selectedBranchs } = report;
    const selectedBranchSettings = useAppSelector(
        (state: any) => state.settings,
    ).selectedBranch;
    const employeeWorkHours = useAppSelector(
        (state: any) => state.employeeWorkHour,
    );

    const exportClassicReport = ({ exportSilae }: any) => {
        const token = localStorage.getItem("token");
        const user = JSON.parse(sessionStorage.getItem("user") ?? "{}");
        const enseigne = user?.license?.enseigne;
        let monthInNum = moment().month() - 1;
        let yearInNum = moment().year();
        const siteName = selectedBranchs
            ?.map((branch: any) => branch.label)
            ?.join(" - ");
        const siteIds = [
            ...selectedBranchs?.map((branch: any) => branch.value),
        ];

        if (report.month) {
            monthInNum = Number(report.month) - 1;
        }
        if (report.year) {
            yearInNum = Number(report.year);
        }
        let startDate, endDate;
        if (report.reportRangeType === "period") {
            startDate = report.workHoursDateRange.startDate;
            endDate = report.workHoursDateRange.endDate;
        } else {
            startDate = moment()
                .year(yearInNum)
                .month(monthInNum)
                .startOf("month")
                .format("YYYY-MM-DD");
            endDate = moment()
                .year(yearInNum)
                .month(monthInNum)
                .endOf("month")
                .format("YYYY-MM-DD");
        }
        if (
            token &&
            siteIds &&
            report.month &&
            report.year &&
            enseigne &&
            siteName
        ) {
            let exportPayload: any = {
                sites: siteIds,
                month: `${report.month}-${report.year}`,
                enseigne,
                siteName,
                startDate,
                endDate,
            };
            if (exportSilae === true) {
                exportPayload.exportSilae = true;
            }
            let payload = {
                token: token,
                exportPayload,
            };

            dispatch(exportClassicReportService(payload)).then((res: any) => {
                if (res.meta.requestStatus === "fulfilled") {
                    if (res?.payload?.error) {
                        Swal.fire({
                            icon: "error",
                            title: "Erreur",
                            text: "Une erreur est survenue lors de la génération du rapport",
                        });
                    } else {
                        window.open(ROUTER.STATIC_FILE2(res.payload.link));
                    }
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Une erreur est survenue lors de la génération du rapport",
                    });
                }
            });
        } else {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Veuillez remplir tous les champs",
            });
        }
    };
    const exportDelayReport = () => {
        const token = localStorage.getItem("token");
        if (token && selectedBranchSettings?._id) {
            const payload = {
                token: token,
                exportPayload: {
                    siteId: selectedBranchSettings?._id,
                    employee: report.selectedEmployee?.id,
                    startDate: report.delayDateRange.startDate,
                    endDate: report.delayDateRange.endDate,
                },
            };
            dispatch(exportDelayReportService(payload)).then((res: any) => {
                if (res.meta.requestStatus === "fulfilled") {
                    if (res?.payload?.error) {
                        Swal.fire({
                            icon: "error",
                            title: "Erreur",
                            text: "Une erreur est survenue lors de la génération du rapport",
                        });
                    } else {
                        window.open(ROUTER.STATIC_FILE2(res.payload.link));
                    }
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Une erreur est survenue lors de la génération du rapport",
                    });
                }
            });
        } else {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Veuillez remplir tous les champs",
            });
        }
    };
    // const weeks = getWeeksInMonth(report.year, report.month);
    const weeks = getWeeksBetweenDates(
        report.workHoursDateRange.startDate,
        report.workHoursDateRange.endDate,
    );
    //TODO:
    //const weeks = [6, 9, 10, 11, 12, 13, 14, 15];
    const getReportFromLocalStorage = () => {
        const reportsJson = localStorage.getItem("report");
        if (reportsJson) {
            const reports = JSON.parse(reportsJson);

            dispatch(
                setReport({
                    hideExtraContractHours: reports.hideExtraContractHours,
                }),
            );
            dispatch(
                setReport({
                    hideRemainingContractHours:
                        reports.hideRemainingContractHours,
                }),
            );
            //walina njibou feha mn settings: getShowOnlyCurrentMonthHours
            // dispatch(
            //     setReport({
            //         showOnlyCurrentMonthHours:
            //             reports.showOnlyCurrentMonthHours,
            //     }),
            // );
            dispatch(setReport({ showWorkedHours: reports.showWorkedHours }));
            dispatch(setReport({ showBreaks: reports.showBreaks }));
            dispatch(setReport({ showMeals: reports.showMeals }));
            dispatch(
                setReport({
                    showWorkedHoursInMinutes: reports.showWorkedHoursInMinutes,
                }),
            );
        }
    };

    const getMonthlyWorkHours = (params?: any) => {
        const { startDate, endDate, showOnlyThisMonthRapport } = params;
        const token = localStorage.getItem("token");
        ///////////////////

        const sessionBranchs = sessionStorage.getItem("branchs");
        const sessionUser = sessionStorage.getItem("user");
        let sessionLicense: any[] = [];
        if (sessionUser) {
            sessionLicense = JSON.parse(sessionUser).realLicense;
        }
        let enseigneName = "";
        if (sessionLicense.length > 0) {
            enseigneName = sessionLicense.find((license: any) => {
                return license._id === selectedBranchSettings?.license;
            })?.enseigne;
        }
        ///////////
        const workHourspayload = {
            licenseId: selectedBranchSettings?.license,
            siteId:
                selectedBranchs?.length > 0
                    ? [...selectedBranchs?.map((item: any) => item.value)]
                    : [selectedBranchSettings?._id],
            weeks: getWeeksBetweenDates(
                startDate ?? report.workHoursDateRange.startDate,
                endDate ?? report.workHoursDateRange.endDate,
            ),
            year: report.year,
            startDate: startDate ?? report.workHoursDateRange.startDate,
            endDate: endDate ?? report.workHoursDateRange.endDate,
            export: params?.isExport ?? false,
            month: params?.month ?? "",
            siteName: selectedBranchSettings.name,
            enseigne: enseigneName,
            showOnlyThisMonthRapport:
                showOnlyThisMonthRapport ?? report.showOnlyCurrentMonthHours,
        };
        if (token && report.month && report.year) {
            dispatch(
                getMonthlyWorkHoursService({
                    token,
                    monthlyWorkHoursPayload: workHourspayload,
                }),
            ).then((res: any) => {
                if (res.meta.requestStatus === "fulfilled") {
                    if (res?.payload?.error) {
                        Swal.fire({
                            icon: "error",
                            title: "Erreur",
                            text: "Une erreur est survenue lors de la génération du rapport",
                        });
                    } else if (params?.isExport) {
                        window.open(ROUTER.STATIC_FILE2(res.payload.link));
                    }
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "Erreur",
                        text: "Une erreur est survenue lors de la génération du rapport",
                    });
                }
            });
        }
    };

    const getWeeklyWorkHours = (weekNumber: any, employeeId: any) => {
        const token = localStorage.getItem("token");
        const workHourspayload = {
            week: weekNumber,
            year: report.year,
            employeeId: employeeId,
            siteId: selectedBranchSettings?._id,
        };
        if (
            token &&
            selectedBranchSettings?._id &&
            weekNumber &&
            report.year &&
            employeeId
        ) {
            dispatch(
                getWeeklyWorkHoursService({
                    token,
                    weeklyWorkHoursPayload: workHourspayload,
                }),
            );
        }
    };
    const getEmployeeFullReport = (weekNumber: any, isoYear: number, employeeId: any) => {
        const token = localStorage.getItem("token");
        let startDate1 = moment()
            .isoWeekYear(isoYear)
            .isoWeek(weekNumber)
            .startOf("isoWeek")
        let reportStartDate = moment(report.workHoursDateRange.startDate);
        if (startDate1.isBefore(reportStartDate)) {
            startDate1 = reportStartDate;
        }
        let endDate1 = moment()
            .isoWeekYear(isoYear)
            .isoWeek(weekNumber)
            .endOf("isoWeek")
        let reportEndDate = moment(report.workHoursDateRange.endDate);
        if (reportEndDate.isBefore(endDate1)) {
            endDate1 = reportEndDate;
        }
        const startDate = startDate1.format("DD-MM-YYYY");
        const endDate = endDate1.format("DD-MM-YYYY");

        const employeeFullReportPayload = {
            employeeId: employeeId,
            siteId:
                selectedBranchs?.length > 0
                    ? [...selectedBranchs?.map((item: any) => item.value)]
                    : [selectedBranchSettings?._id],
            startDate,
            endDate,
            options: {
                useIndisponibility: false,
                useAbsenceFromPlans: true,
                useShiftsAbsent: true,
                useShiftsWorking: true,
                useCongeValidated: true,
                workShiftOverVacations: true,
                showRecordsForAllSites: report.showWorkedHoursEverywhere,
            },
        };
        if (token && weekNumber && report.year && employeeId) {
            dispatch(
                getEmployeeFullReportService({
                    token,
                    employeeFullReportPayload,
                }),
            );
        }
    };

    const getEmployeeWorkHours = (
        employeeID: string,
        startDay: string,
        endDay: string,
    ) => {
        if (selectedBranchSettings._id && startDay && endDay && employeeID) {
            dispatch(
                getEmployeeWorkHoursService({
                    siteID: selectedBranchSettings._id,
                    startDay,
                    endDay,
                    employeeID,
                }),
            );
        }
    };

    const setYear = (year: number | string | null) => {
        dispatch(updateReport({ year }));
    };
    const setMonth = (month: number | string | null) => {
        dispatch(updateReport({ month }));
    };
    const setDay = (day: number | string | null) => {
        dispatch(updateReport({ day }));
    };
    const setSelectedEmployee = (selectedEmployee: EmployeeState | null) => {
        dispatch(updateReport({ selectedEmployee }));
    };
    const setShowedEmployee = (showedEmployee: EmployeeState | null) => {
        dispatch(updateReport({ showedEmployee }));
    };
    const setFirstDayOfWeek = (firstDayOfWeek: number | string | null) => {
        dispatch(
            updateReport({
                selectedShift: { firstDayOfWeek },
            }),
        );
    };
    const setLastDayOfWeek = (lastDayOfWeek: number | string | null) => {
        dispatch(
            updateReport({
                selectedShift: {
                    lastDayOfWeek,
                },
            }),
        );
    };
    const setHideRemainingContractHours = (
        hideRemainingContractHours: boolean,
    ) => {
        dispatch(updateReport({ hideRemainingContractHours }));
    };
    const setHideExtraContractHours = (hideExtraContractHours: boolean) => {
        dispatch(updateReport({ hideExtraContractHours }));
    };
    const setShowOnlyCurrentMonthHours = async (
        showOnlyCurrentMonthHours: boolean,
        reportType?: string,
    ) => {
        dispatch(updateReport({ showOnlyCurrentMonthHours }));
        if (reportType === "month") {
            updateRportOptionInSettings({ showOnlyCurrentMonthHours });
        }
    };
    const setShowWorkedHoursEverywhere = async (
        showWorkedHoursEverywhere: boolean,
        reportType?: string,
    ) => {
        dispatch(updateReport({ showWorkedHoursEverywhere }));
        if (reportType === "month") {
            updateRportOptionInSettings({ showWorkedHoursEverywhere });
        }
    };
    const updateRportOptionInSettings = async ({
        showOnlyCurrentMonthHours,
        showWorkedHoursEverywhere,
    }: any) => {
        const selectedBranch = sessionStorage.getItem("selectedBranch");
        if (selectedBranch) {
            const siteId = JSON.parse(selectedBranch)._id;
            let obj: any = {};
            if (showOnlyCurrentMonthHours !== undefined) {
                obj.showOnlyThisMonthRapport = showOnlyCurrentMonthHours;
            }
            if (showWorkedHoursEverywhere !== undefined) {
                obj.showInAllDepartments = showWorkedHoursEverywhere;
            }

            try {
                const response = await fetch(
                    API.client.settings.branch.updateSiteSettings(siteId),
                    {
                        method: "POST",
                        headers: {
                            authorization: `Bearer ${localStorage.getItem(
                                "token",
                            )}`,
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(obj),
                    },
                );
                if (response.ok) {
                    dispatch(
                        updateReport({
                            showOnlyCurrentMonthHours,
                            showWorkedHoursEverywhere,
                        }),
                    );
                } else {
                    console.error("Failed to fetch stats");
                }
            } catch (error) {
                console.error("Error fetching stats:", error);
            }
        }
    };
    const getShowOnlyCurrentMonthHours = async () => {
        const selectedBranch = sessionStorage.getItem("selectedBranch");
        if (selectedBranch) {
            const siteId = JSON.parse(selectedBranch)._id;
            try {
                const response = await fetch(
                    API.client.settings.branch.getSiteSettings(siteId),
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bearer ${localStorage.getItem(
                                "token",
                            )}`,
                        },
                    },
                );
                if (response.ok === true) {
                    let data = await response.json();
                    dispatch(
                        updateReport({
                            showOnlyCurrentMonthHours:
                                data.showOnlyThisMonthRapport,
                            showWorkedHoursEverywhere:
                                data.showInAllDepartments,
                        }),
                    );
                }
            } catch (err) {
                console.log(err);
            }
        } else {
            // dispatch(updatePlanning({ absenceColors: null }));
        }
    };
    const setShowWorkedHours = (showWorkedHours: boolean) => {
        dispatch(updateReport({ showWorkedHours }));
    };
    const setShowBreaks = (showBreaks: boolean) => {
        dispatch(updateReport({ showBreaks }));
    };
    const setShowMeals = (showMeals: boolean) => {
        dispatch(updateReport({ showMeals }));
    };
    const setShowWorkedHoursInMinutes = (showWorkedHoursInMinutes: boolean) => {
        dispatch(updateReport({ showWorkedHoursInMinutes }));
    };
    const resetSelectedShift = () => {
        dispatch(clearSelectedShift());
    };

    const getEployeesDelay = async () => {
        const selectedBranch = sessionStorage.getItem("selectedBranch");
        if (selectedBranch) {
            const parsedBranch = JSON.parse(selectedBranch);
            if (parsedBranch) {
                if (
                    report.selectedEmployee &&
                    report.delayDateRange.startDate &&
                    report.delayDateRange.endDate &&
                    report?.selectedEmployee
                ) {
                }
                try {
                    const employee = report.selectedEmployee.id;
                    const startDate = report.delayDateRange.startDate;
                    const endDate = report.delayDateRange.endDate;
                    const siteId = parsedBranch._id;
                    const response = await fetch(
                        API.client.employees.fetchEployeesDelay,
                        {
                            method: "POST",
                            headers: {
                                authorization: `Bearer ${localStorage.getItem(
                                    "token",
                                )}`,
                                "Content-Type": "application/json",
                            },
                            body: JSON.stringify({
                                employee,
                                startDate,
                                endDate,
                                siteId,
                            }),
                        },
                    );
                    if (response.ok) {
                        const data = await response.json();
                        setDelayData(data);
                    } else {
                        console.error("Failed to fetch stats");
                    }
                } catch (error) {
                    console.error("Error fetching stats:", error);
                }
            }
        }
    };
    const setDelayDateRange = ({ startDate, endDate }: any) => {
        dispatch(updateReport({ startDate, endDate }));
    };
    const setDelayData = (data: any) => {
        dispatch(updateReport({ delayData: data }));
    };
    const setReportRange = ({ startDate, endDate }: any) => {
        dispatch(setWorkHoursDateRange({ startDate, endDate }));
    };

    const generateAttendanceSheet = ({
        weekNumber,
        employeeId,
    }: {
        weekNumber: number;
        employeeId?: string;
    }) => {
        Swal.showLoading();
        const token = localStorage.getItem("token");
        let attendancePDFPayload: any = {
            siteId: selectedBranchSettings?._id,
            week: weekNumber,
            year: report.year,
        };
        if (employeeId) {
            attendancePDFPayload.employeeID = employeeId;
        }
        dispatch(
            generateAttendancePDFService({
                token,
                attendancePDFPayload,
            }),
        ).then((res) => {
            Swal.hideLoading();
            Swal.close();
            if (res.meta?.requestStatus === "fulfilled") {
                if (res.payload?.error) {
                    Swal.fire({
                        icon: "error",
                        title: "Erreur",
                        text: `${res.payload?.message}`,
                    });
                } else {
                    const link = res.payload?.link;
                    window.open(ROUTER.STATIC_FILE2(link), "_blank");
                }
            } else {
                if (res.payload.error) {
                    Swal.fire({
                        icon: "error",
                        title: "Erreur de serveur",
                        text: "Nous avons rencontré une erreur de serveur. Veuillez réessayer plus tard.",
                    });
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "Erreur de serveur",
                        text: "Nous avons rencontré une erreur de serveur. Veuillez réessayer plus tard.",
                    });
                }
            }
        });
    };
    const handleSetBranchs = (branchs: any) => {
        dispatch(setBranchs(branchs));
    };
    const handleSetSelectedBranchs = (selectedBranchs: any) => {
        dispatch(setSelectedBranchs(selectedBranchs));
    };
    const handleSetReportRangeType = (type: any) => {
        dispatch(setReportRangeType(type));
    };
    const handleSetWorkHoursDataLength = (length: number) => {
        dispatch(setDataLength({ workHours: length }));
    };
    const handleSetPayRollDataLength = (length: number) => {
        dispatch(setDataLength({ payRoll: length }));
    };
    return {
        report,
        employeeWorkHours,
        weeks,
        exportClassicReport,
        exportDelayReport,
        getMonthlyWorkHours,
        getWeeklyWorkHours,
        getEmployeeFullReport,
        setYear,
        setMonth,
        setDay,
        setSelectedEmployee,
        setShowedEmployee,
        setFirstDayOfWeek,
        setLastDayOfWeek,
        setHideRemainingContractHours,
        setHideExtraContractHours,
        setShowOnlyCurrentMonthHours,
        setShowWorkedHoursEverywhere,
        setShowWorkedHours,
        setShowBreaks,
        setShowMeals,
        setShowWorkedHoursInMinutes,
        resetSelectedShift,
        getReportFromLocalStorage,
        getEployeesDelay,
        getEmployeeWorkHours,
        setDelayData,
        setDelayDateRange,
        setReportRange,
        generateAttendanceSheet,
        getShowOnlyCurrentMonthHours,
        updateRportOptionInSettings,
        branchs,
        selectedBranchs,
        handleSetSelectedBranchs,
        handleSetBranchs,
        handleSetReportRangeType,
        reportRangeType: report?.reportRangeType,
        handleSetWorkHoursDataLength,
        workHoursDataLength: report?.dataLength.workHours,
        handleSetPayRollDataLength,
        payRollDataLength: report?.dataLength.payRoll,
    };
};
