import React from "react";
import {
    fetchEmployees,
    createEmployee,
    fetchEmployee,
    fetchEmployeeInfos,
    fetchEmployeeDisponibilities,
    createUserUnavaibility,
    updateUserUnavaibility,
    fetchRegister,
    restoreEmp,
    archiveEmp,
    updateEmployee,
    updateEmployeeInfos,
    updateContract,
    removeUnavaibility,
    fetchEmployeeDocuments,
    addEmployeeDocument,
    deleteEmployeeDocument,
    createEmployeePrimes,
    updateEmployeePrimes,
    fetchEmployeePrimes,
    deleteEmployeePrimes,
    fetchPrimesTypes,
    fetchContracts,
    createContract,
    fetchEmployeeWorkHours,
    fetchEmployeeHolidays,
    updateEmployeePassword,
    uploadNewDocument,
    updateExistingDocument,
    downloadRegistre,
    deleteExistingDocument,
    deleteFolder,
    createEmployeeFolder,
    editEmployeeFolder,
} from "../redux/features/employees/service";
import { actions } from "../redux/features/employees/employeesSlice";

import { useAppDispatch, useAppSelector } from "./useReduxHooks";

import {
    FetchEmployeesRequest,
    CreateEployeeRequest,
    FetchEmployeeRequest,
    FetchEmployeeInfosRequest,
    FetchEmployeeDisponibilitiesRequest,
    FetchRegisterRequest,
    RestoreEmployeeRequest,
    UpdateEployeeRequest,
    UpdateEployeeInfosRequest,
    ContractUpdateRequest,
    CreateEmployeeDisponibilitiesRequest,
    DeleteUnavaibilityRequest,
    FetchEmployeeDocuments,
    AddEmployeeDocument,
    DeleteEmployeeDocument,
    CreateEmployeePrimes,
    UpdateEmployeePrimes,
    FetchEmployeePrimes,
    DeleteEmployeePrimes,
    FetchPrimesTypes,
    FetchContractsRequest,
    ContractCreateRequest,
    FetchEmployeeWorkHoursRequest,
    FetchEmployeeHolidaysRequest,
    UpdateEmployeePasswordRequest,
} from "../redux/features/employees/types";
import { shallowEqual } from "react-redux";
import moment from "moment";

export interface EmployeesFilterProps {
    role: string[];
    name: string;
    actif: boolean;
    site?: string;
    deleted: false | null;
    niveau?: number[];
}

export interface SortByProps {
    field: string;
    order: string;
}

export function useEmployees() {
    const dispatch = useAppDispatch();

    const {
        filtredEmployees,
        filtredArchived,
        employeeForEdit,
        employeeInfos,
        disponibilities,
        filtredRegister,
        actionLoading,
        filterBy,
        documents,
        selectedDocument,
        listLoading,
        primes,
        primeTypes,
        contracts,
        shifts,
        holidays,
        sortBy,
        allActiveEmployees,
        filteredFromAllSites,
        archived,
        activeEmps,
        filteredFromAllEmpsInApp,
    } = useAppSelector((state) => state.employees, shallowEqual);

    const selectedBranchSettings = useAppSelector(
        (state: any) => state.settings,
    ).selectedBranch;

    const onUpdateFilterBy = (props: EmployeesFilterProps) => {
        //setFilterBy(props);
        dispatch(
            actions.filterUpdated({
                filterBy: props,
            }),
        );
    };

    React.useEffect(() => {
        if (selectedBranchSettings && selectedBranchSettings._id) {
            onUpdateFilterBy({
                ...filterBy,
                name: "",
                niveau: [],
                role: [],
                site: selectedBranchSettings._id
                    ? selectedBranchSettings._id.toString()
                    : undefined,
            });
        }
    }, [selectedBranchSettings]);

    const onUpdateSortBy = (props: SortByProps) => {
        dispatch(
            actions.sortUpdated({
                field: props.field,
                order: props.order,
            }),
        );
    };

    const sortPrimesByField = (field: any, sort: any) => {
        dispatch(actions.sortPrimesByFieldName({ field, sort }));
    };

    const getEmployees = (queryParams: FetchEmployeesRequest) => {
        dispatch(fetchEmployees(queryParams));
    };

    const addEmployee = (values: CreateEployeeRequest) => {
        // console.log("valuesvalues", values);
        return dispatch(createEmployee(values));
    };

    const editEmployee = (values: UpdateEployeeRequest) => {
        return dispatch(updateEmployee(values));
    };

    const editEmployeeInfos = (values: UpdateEployeeInfosRequest) => {
        return dispatch(updateEmployeeInfos(values));
    };

    const getEmployee = (id: FetchEmployeeRequest) => {
        dispatch(fetchEmployee(id));
    };

    const getEmployeeInfos = (id: FetchEmployeeInfosRequest) => {
        dispatch(fetchEmployeeInfos(id));
    };

    const getDisponibilities = (id: FetchEmployeeDisponibilitiesRequest) => {
        dispatch(fetchEmployeeDisponibilities(id));
    };

    const createUnavaibility = (
        values: CreateEmployeeDisponibilitiesRequest,
    ) => {
        return dispatch(createUserUnavaibility(values));
    };

    const updateUnavaibility = (values: any) => {
        return dispatch(updateUserUnavaibility(values));
    };

    const getRegister = (id: FetchRegisterRequest) => {
        dispatch(fetchRegister(id));
    };

    const restoreEmployee = (id: RestoreEmployeeRequest) => {
        return dispatch(restoreEmp(id));
    };

    const archiveEmployee = (id: RestoreEmployeeRequest) => {
        return dispatch(archiveEmp(id));
    };

    const editContract = (values: ContractUpdateRequest) => {
        return dispatch(updateContract(values));
    };

    const addContract = (values: ContractCreateRequest) => {
        return dispatch(createContract(values));
    };

    const deleteUnavaibility = (values: DeleteUnavaibilityRequest) => {
        return dispatch(removeUnavaibility(values));
    };

    const getEmployeeDocuments = async (values: FetchEmployeeDocuments) => {
        return await dispatch(fetchEmployeeDocuments(values));
    };

    const createEmployeeDocument = (values: AddEmployeeDocument) => {
        return dispatch(addEmployeeDocument(values));
    };

    const removeEmployeeDocument = (values: DeleteEmployeeDocument) => {
        return dispatch(deleteEmployeeDocument(values));
    };

    const createPrime = (values: CreateEmployeePrimes) => {
        return dispatch(createEmployeePrimes(values));
    };

    const updatePrime = (values: UpdateEmployeePrimes) => {
        return dispatch(updateEmployeePrimes(values));
    };

    const fetchPrimes = (values: FetchEmployeePrimes) => {
        return dispatch(fetchEmployeePrimes(values));
    };

    const deletePrime = (values: DeleteEmployeePrimes) => {
        return dispatch(deleteEmployeePrimes(values));
    };

    const getPrimesTypes = (values: FetchPrimesTypes) => {
        return dispatch(fetchPrimesTypes(values));
    };

    const getContracts = (values: FetchContractsRequest) => {
        return dispatch(fetchContracts(values));
    };

    const getEmployeeWorkHours = (values: FetchEmployeeWorkHoursRequest) => {
        return dispatch(fetchEmployeeWorkHours(values));
    };

    const getEmployeeHolidays = (values: FetchEmployeeHolidaysRequest) => {
        return dispatch(fetchEmployeeHolidays(values));
    };

    const editEmployeePassword = (values: UpdateEmployeePasswordRequest) => {
        return dispatch(updateEmployeePassword(values));
    };

    const manageSelectedDocument = (newDocument: any) => {
        return dispatch(actions.updateSelectedDocument(newDocument));
    };

    const addNewDocument = async (
        file: any,
        fileName: string,
        employeeId: string,
        folderId: string,
    ) => {
        await dispatch(
            uploadNewDocument({
                file,
                fileName,
                employeeId,
                folderId,
            }),
        );
    };
    const editExistingDocument = async (
        fileId: string,
        folderId: string,
        fileName: string,
    ) => {
        await dispatch(
            updateExistingDocument({
                fileId,
                fileName,
                folderId,
            }),
        );
    };
    const handleDownloadRegistre = async (siteId: string) => {
        return await dispatch(
            downloadRegistre({
                siteId,
            }),
        );
    };
    const dropExistingDocument = async (documentID: string) => {
        await dispatch(deleteExistingDocument({ fileId: documentID }));
    };

    const removeFolder = async (
        folderId: string,
        employeeId: string | undefined,
    ) => {
        return dispatch(deleteFolder({ folderId, employeeId }));
    };

    const createFolder = async (
        name: string,
        permissions: string[],
        employee: any,
    ) => {
        return dispatch(
            createEmployeeFolder({
                name,
                permissions,
                employee,
            }),
        );
    };

    const editFolder = async (
        folderId: string,
        name: string,
        permissions: string[],
        onlyThisEmployee: boolean,
        employeeId: string,
    ) => {
        return dispatch(
            editEmployeeFolder({
                folderId,
                name,
                permissions,
                onlyThisEmployee,
                employeeId,
            }),
        );
    };
    return {
        getEmployees,
        getEmployee,
        archived: filtredArchived.archived,
        allEmpsInApp: filteredFromAllEmpsInApp,
        allActiveEmps: activeEmps,
        employees: filtredEmployees.employees,
        allActiveEmployees,
        filteredFromAllSites,
        totalEmployees: filtredEmployees.totalEmployees,
        totalEmployeesPages: filtredEmployees.totalEmployeesPages,
        totalArchived: filtredArchived.totalArchived,
        totalArchivedPages: filtredArchived.totalArchivedPages,
        addEmployee,
        employeeForEdit,
        getEmployeeInfos,
        employeeInfos,
        getDisponibilities,
        disponibilities,
        createUnavaibility,
        updateUnavaibility,
        getRegister,
        register: filtredRegister,
        actionLoading,
        restoreEmployee,
        archiveEmployee,
        editEmployee,
        editEmployeeInfos,
        editContract,
        onUpdateFilterBy,
        filterBy,
        deleteUnavaibility,
        getEmployeeDocuments,
        createEmployeeDocument,
        removeEmployeeDocument,
        documents,
        selectedDocument,
        primes,
        listLoading,
        fetchPrimes,
        createPrime,
        updatePrime,
        deletePrime,
        getPrimesTypes,
        primeTypes,
        getContracts,
        contracts,
        addContract,
        getEmployeeWorkHours,
        shifts,
        getEmployeeHolidays,
        holidays,
        editEmployeePassword,
        onUpdateSortBy,
        sortBy,
        manageSelectedDocument,
        addNewDocument,
        editExistingDocument,
        handleDownloadRegistre,
        dropExistingDocument,
        sortPrimesByField,
        removeFolder,
        createFolder,
        editFolder,
    };
}
