import React from "react";
import { useIntl } from "react-intl";
import ZInput from "../../atoms/ZInput";
import ReactCountryFlag from "react-country-flag";
import DatepickerInput from "../../atoms/DatepickerInput";
import Button from "../../atoms/ZButton";
import InputInfos from "../../atoms/InputInfos";
import { Female, Male } from "../../../Icons";
import { genderOptions, getCountriesOptions } from "../../../Options";
import SelectInput from "../../atoms/SelectInput";
import TextData from "../../atoms/TextData";

export default function PersonalInfo({
    countries,
    employee,
    onChange,
    onBlur,
    onSubmit,
    actionLoading,
}: PersonalInfoInterface) {
    const intl = useIntl();
    /*
  const genderOptions = [
    {
      label: intl.formatMessage({ id: "MENU.MALE" }),
      value: "male",
      icon: <Male />,
    },
    {
      label: intl.formatMessage({ id: "MENU.FEMALE" }),
      value: "female",
      icon: <Female />,
    },
  ];
*/
    const countriesOptions = getCountriesOptions(countries);

    return (
        <div>
            <h2 className="text-v-800 font-bold text-9xl border-b border-n-100 pb-3 mb-10">
                {intl.formatMessage({
                    id: "EMPLOYEE.EDIT.PERSONAL_INFO.TITLE",
                })}
            </h2>
            <div className="flex items-center flex-wrap gap-5 mt-4">
                <div className="basis-[200px] grow">
                    <div
                        className="flex items-center"
                        style={{ marginBottom: "3px" }}
                    >
                        <label className="text-n-400 font-medium text-base">
                            {intl.formatMessage({ id: "PERSONALINFOS.GENDER" })}
                        </label>
                        <InputInfos rup={true} dpae={true} />
                    </div>

                    <SelectInput
                        placeholder=""
                        title=""
                        style={{
                            height: "48px",
                        }}
                        options={genderOptions}
                        onSelectOption={(option: any) => {
                            onChange("gender", option);
                        }}
                        selectedValue={employee.gender.value}
                        isSearchable={false}
                    />
                </div>
                <div className="basis-[200px] grow">
                    <DatepickerInput
                        label={intl.formatMessage({
                            id: "PERSONALINFOS.BIRTHDATE",
                        })}
                        required={false}
                        field={employee.birthday}
                        onUpdate={(value) => {
                            const convertedValue = value.format("YYYY-MM-DD");
                            onChange("birthday", convertedValue);
                        }}
                        onBlur={(field) => {
                            onBlur(field);
                        }}
                        rup={true}
                        dpae={true}
                        yearsType={"birth"}
                    />
                </div>
            </div>
            <div className="flex flex-wrap items-center gap-5 mt-4">
                <div className="basis-[200px] grow">
                    <label
                        className="text-n-400 font-medium text-base"
                        style={{ marginBottom: "3px" }}
                    >
                        {intl.formatMessage({
                            id: "PERSONALINFOS.COUNTRY_OF_BIRTH",
                        })}
                    </label>

                    <SelectInput
                        title=""
                        placeholder=""
                        style={{
                            height: "48px",
                        }}
                        options={countriesOptions}
                        onSelectOption={(option) => {
                            onChange("nationality", option);
                        }}
                        selectedValue={employee.nationality.value}
                    />
                </div>
                <div className="basis-[200px] grow">
                    <ZInput
                        label={intl.formatMessage({
                            id: "PERSONALINFOS.CITY_OF_BIRTH",
                        })}
                        required={false}
                        field={employee.cityOfBirth}
                        onUpdate={(value) => {
                            const villeDeNaissanceRegex = /^[a-z A-Z]+$/;
                            if (
                                villeDeNaissanceRegex.test(String(value)) ||
                                String(value) === ""
                            ) {
                                onChange("cityOfBirth", value);
                            }
                        }}
                        onBlur={onBlur}
                        dpae={true}
                    />
                </div>
                <div className="basis-[200px] grow">
                    <ZInput
                        label={intl.formatMessage({
                            id: "PERSONALINFOS.DEPARTEMENT_OF_BIRTH",
                        })}
                        required={false}
                        field={employee.departmentOfBirth}
                        onUpdate={(value) => {
                            const pattern = /^[0-9]+$/;
                            if (
                                pattern.test(String(value)) ||
                                String(value) === ""
                            ) {
                                onChange("departmentOfBirth", value);
                            }
                        }}
                        onBlur={onBlur}
                        dpae={true}
                    />
                </div>
            </div>
            <div className="flex flex-wrap items-center gap-5 mt-4">
                <div className="basis-[200px] grow">
                    <ZInput
                        label={intl.formatMessage({
                            id: "PERSONALINFOS.ADRESS",
                        })}
                        required={false}
                        field={employee.adress}
                        onUpdate={(value) => {
                            onChange("adress", value);
                        }}
                        onBlur={onBlur}
                        rup={true}
                        type="text"
                        maxLength={80}
                    />
                </div>
            </div>
            <div className="flex flex-wrap gap-5 mt-4">
                <div className="basis-[200px] grow">
                    <ZInput
                        label={intl.formatMessage({
                            id: "PERSONALINFOS.ZIPCODE",
                        })}
                        required={false}
                        field={employee.zipCode}
                        onUpdate={(value) => {
                            onChange("zipCode", value);
                        }}
                        onBlur={onBlur}
                        rup={true}
                        isNumber={true}
                        type="text"
                        maxLength={5}
                    />
                </div>
                <div className="basis-[200px] grow">
                    <ZInput
                        label={intl.formatMessage({ id: "PERSONALINFOS.CITY" })}
                        required={false}
                        field={employee.city}
                        onUpdate={(value) => {
                            onChange("city", value);
                        }}
                        onBlur={onBlur}
                        rup={true}
                    />
                </div>
            </div>
            {/* <div className='flex flex-wrap gap-5 mt-4'>
                <div className='basis-[200px] grow'>
                    <ZInput
                        label='Nir Key'
                        required={false}
                        field={employee.nirKey}
                        onUpdate={(value) => {
                            onChange("nirKey", value);
                        }}
                        onBlur={onBlur}
                        rup={true}
                        dpae={true}
                        isNumber={true}
                    />
                </div>
                <div className='basis-[200px] grow'>
                    <ZInput
                        label='Nir ID'
                        required={false}
                        field={employee.nirID}
                        onUpdate={(value) => {
                            onChange("nirID", value);
                        }}
                        onBlur={onBlur}
                        rup={true}
                        dpae={true}
                    />
                </div>
            </div> */}
            <div className="flex gap-5 mt-4">
                <div className="basis-[200px] grow">
                    <ZInput
                        label={intl.formatMessage({
                            id: "PERSONALINFOS.SOCIAL_NUMBER",
                        })}
                        required={false}
                        field={employee.socialNumber}
                        onUpdate={(value) => {
                            onChange("socialNumber", value);
                        }}
                        onBlur={onBlur}
                        dpae={true}
                    />
                </div>
            </div>
            <div
                className="flex mt-4"
                style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                }}
            >
                <div style={{ display: "flex" }}>
                    <div style={{ width: "55px" }}>
                        <span className="bg-n-0 rounded-[10px] py-[1.5px] px-[6px] text-n-600 text-base">
                            DPAE
                        </span>
                        {/* <span style={{ color: "red" }}>*</span> */}
                    </div>
                    <TextData color="#8e96c1">
                        La déclaration préalable à l'embauche
                    </TextData>
                </div>
                <div style={{ display: "flex" }}>
                    <div style={{ width: "55px" }}>
                        <span className="bg-n-0 rounded-[10px] py-[1.5px] px-[6px] text-n-600 text-base">
                            RUP
                        </span>
                        {/* <span style={{ color: "red" }}>*</span> */}
                    </div>
                    <TextData color="#8e96c1">
                        Registre unique du personnel
                    </TextData>
                </div>
            </div>
            <div className="mt-5 py-8 border-t border-n-100">
                <div className="flex gap-5 justify-end">
                    <Button
                        onClick={onSubmit}
                        variant="primary"
                        title={intl.formatMessage({ id: "MENU.UPDATE" })}
                        size="lg"
                        loading={actionLoading}
                    />
                </div>
            </div>
        </div>
    );
}

interface PersonalInfoInterface {
    employee: any;
    onChange: (field: string, data: any) => void;
    onBlur: (field: string) => void;
    countries: { name: string; code: string }[];
    onSubmit: () => void;
    cancelAction: () => void;
    actionLoading: boolean;
}
