import Input from "../../../components/atoms/Input";
import Radio from "../../../components/atoms/Radio";
import ToggleButton from "../../../components/atoms/Toggle";
import PauseConditions from "../../../components/organism/PauseConditions";
import { useBranch } from "../../../hooks/useBranch";

import { extractNumber } from "../../../_helpers/Functions";
import Ticket from "../../../components/atoms/Ticket";

const moment = require("moment");

moment.locale("fr"); // Set locale to French

const DaysOff = (props: any) => {
    const { offDays, setDaysoff } = props;
    // Get an array of weekday names from Monday to Sunday
    const daysOfWeek = Array.from({ length: 7 }, (_, index) => {
        const day = moment().weekday(index).format("dddd");
        return day;
    });

    return (
        <div
            style={{
                display: "flex",
                flexWrap: "wrap",
                gap: "5px",
                marginBottom: "10px",
            }}
        >
            {daysOfWeek?.map((day: string, index: number) => {
                const checked = offDays.includes(index);
                return (
                    <Ticket
                        // id={index}
                        key={index}
                        text={day}
                        border={`1px solid ${checked ? "#eaf4f7" : "var(--t-500)"}`}
                        backgroundColor={checked ? "var(--t-500)" : "#eaf4f7"}
                        textColor={checked ? "#eaf4f7" : "var(--t-500)"}
                        handleClick={() => {
                            let newArr: number[] = [];
                            if (checked) {
                                for (let day of offDays) {
                                    if (day !== index)
                                        newArr = [...newArr, day];
                                }
                            } else {
                                newArr = [...offDays, index];
                            }
                            setDaysoff(newArr);
                        }}
                        // handleEdit={handleUpdate}
                        // handleClose={handleDelete}
                    />
                );
            })}
        </div>
    );
};
export const BranchProps = () => {
    const {
        Settings,
        selectedBranchSettings,
        branchSettings,
        saveSelectedBranch,
        getBranchSettings,

        updateBranchSettings,

        setOpeningHour,
        setClosingHour,
        setBreakDuration,
        setDaysForUnavailabilityNotice,
        setDaysForVacationNotice,
        setEnableRegister,
        setUseRepas,
        setUsePause,
        setShowHours,
        setEnablePayroll,
        setSalaryCalculationFrequency,
        setWorkDaysPerMonth,
        setDaysoff,
    } = useBranch();
    const CalculSalaireSettings = () => {
        let configOptions = [
            {
                titleColor: "#2A346D",
                titleSize: "16px",
                titleWeight: "bolder",
                descriptionColor: "#6F78AB",
                descriptionSize: "12",
                descriptionWeight: "normal",
                optionTitle: "",
                optionDescription: "",
                optionInput: (
                    <Radio
                        selectedValue={branchSettings.salaries}
                        items={[
                            {
                                itemValue: "hourly",
                                itemDesignation: "Par heures",
                                itemDescription:
                                    "Cette fonctionnalité permet de calculer la masse salariale en tenant compte des heures pointées (ou des heures renseignées manuellement).Exemple : Sébastien a un contrat de 35h. Dans sa fiche on indique que c’est 10€ l’heure et 15€ l’heure supplémentaire. Donc pour 40h, le système calculera 35h x 10€ et 5h x 15€.",
                            },
                            {
                                itemValue: "daily",
                                itemDesignation: "Par jours",
                                itemDescription:
                                    "Cette fonctionnalité permet à l'employeur de calculer et de gérer la masse salariale des employés par jour travaillé (spécifique à quelques secteurs d’activités).",
                            },
                        ]}
                        getCurrentValue={handleRadioCurrentValue}
                    />
                ),
            },
        ];
        if (branchSettings.salaries === "daily") {
            configOptions.push({
                titleColor: "#2A346D",
                titleSize: "16px",
                titleWeight: "bolder",
                descriptionColor: "#6F78AB",
                descriptionSize: "13",
                descriptionWeight: "normal",
                optionTitle: "Nombre de jour de travail par mois.",
                optionDescription:
                    "Cette fonctionnalité permet à l'employeur de calculer et de gérer le salaire des employés (spécifique à quelques secteurs d’activités). ",
                optionInput: (
                    <Input
                        captionText={null}
                        inputWidth="100px"
                        placeholder=""
                        borderColor="gray"
                        borderWidth={1}
                        value={`${branchSettings.daysPerMonth}`}
                        onChange={(value: any) => {
                            console.log(" value = ", value);
                            setWorkDaysPerMonth(extractNumber(value));
                        }}
                        regex={/^[0-9]+$/}
                        maxLength={2}
                        max="31"
                        style={{
                            inputStyle: {
                                padding: "10px 5px",
                                outline: "none",
                            },
                        }}
                    />
                ),
            });
        }
        if (branchSettings.paie) {
            return {
                headerTitle: "Calcul du salaire",
                headerDescription:
                    "Cette fonctionnalité permet de calculer la paie en tenant compte uniquement du prix de l’heure au contrat et de l’heure supplémentaire. Exemple : Sébastien a un contrat de 35h. Dans sa fiche on indique que c’est 10€ l’heure et 15€ l’heure supplémentaire. Donc pour 40h, le sytéme calculera 35h x 10€ et 5h x 15€.",
                configOptions: configOptions,
            };
        } else {
            return {};
        }
    };
    const handleRadioCurrentValue = (data: any) => {
        setSalaryCalculationFrequency(data.itemValue);
    };
    return [
        {
            headerTitle: "Heure de travail",
            headerDescription:
                "Permet d’adapter la pointeuse à votre fonctionnement. Exemple : On est le 1er janvier, un employé depointe après 00h (donc le 2 janvier), le pointage restera sur la journée du 1er.",
            configOptions: branchSettings.usePause
                ? [
                      {
                          optionTitle: "Heure d'ouverture",
                          titleColor: "#2A346D",
                          titleSize: "16px",
                          titleWeight: "bolder",
                          descriptionColor: "#6F78AB",
                          descriptionSize: "13",
                          descriptionWeight: "normal",
                          optionDescription:
                              "L’heure à laquelle la première personne arrive sur les lieux. ",

                          optionInput: (
                              <Input
                                  captionText={null}
                                  inputWidth="auto"
                                  placeholder=""
                                  borderColor="gray"
                                  borderWidth={1}
                                  value={branchSettings.ouverture}
                                  type="time"
                                  onChange={(value: any) =>
                                      setOpeningHour(value)
                                  }
                                  style={{
                                      inputStyle: {
                                          padding: "10px 5px",
                                          outline: "none",
                                      },
                                  }}
                              />
                          ),
                      },
                      {
                          titleColor: "#2A346D",
                          titleSize: "16px",
                          titleWeight: "bolder",
                          descriptionColor: "#6F78AB",
                          descriptionSize: "13",
                          descriptionWeight: "normal",
                          optionTitle: "Heure de fermeture",
                          optionDescription:
                              "L’heure à laquelle la dernière personne quitte les lieux . ",
                          optionInput: (
                              <Input
                                  captionText={null}
                                  inputWidth="auto"
                                  placeholder=""
                                  borderColor="gray"
                                  borderWidth={1}
                                  value={branchSettings.fermeture}
                                  onChange={(value: any) =>
                                      setClosingHour(value)
                                  }
                                  type="time"
                                  style={{
                                      inputStyle: {
                                          padding: "10px 5px",
                                          outline: "none",
                                      },
                                  }}
                              />
                          ),
                      },
                      {
                          titleColor: "#2A346D",
                          titleSize: "16px",
                          titleWeight: "bolder",
                          descriptionColor: "#6F78AB",
                          descriptionSize: "13",
                          descriptionWeight: "normal",
                          optionTitle: "Pause par défaut (en minutes)",
                          optionDescription:
                              "Temps de repos régulier que les employés peuvent prendre pendant leur journée de travail. Au moment de planifier et afin d’éviter de saisir manuellement le temps de pause, vous aurez la possibilité de cocher la pause (option à décocher en cas de pause personnalisée).",
                          optionInput: (
                              <Input
                                  captionText={null}
                                  inputWidth="100px"
                                  placeholder=""
                                  borderColor="gray"
                                  borderWidth={1}
                                  value={`${branchSettings.pause}`}
                                  onChange={(value: any) => {
                                      setBreakDuration(extractNumber(value));
                                  }}
                                  regex={/^[0-9]+$/}
                                  maxLength={4}
                                  style={{
                                      inputStyle: {
                                          padding: "10px 5px",
                                          outline: "none",
                                      },
                                  }}
                              />
                          ),
                      },
                      {
                          titleColor: "#2A346D",
                          titleSize: "16px",
                          titleWeight: "bolder",
                          descriptionColor: "#6F78AB",
                          descriptionSize: "13",
                          descriptionWeight: "normal",
                          optionTitle: "Jours de repos hebdomadaire",
                          optionDescription: "",
                          optionInput: "",
                          optionInput2: (
                              <DaysOff
                                  offDays={branchSettings.offDays}
                                  setDaysoff={setDaysoff}
                              />
                          ),
                      },
                  ]
                : [
                      {
                          optionTitle: "Heure d'ouverture",
                          titleColor: "#2A346D",
                          titleSize: "16px",
                          titleWeight: "bolder",
                          descriptionColor: "#6F78AB",
                          descriptionSize: "13",
                          descriptionWeight: "normal",
                          optionDescription:
                              "L’heure à laquelle la première personne arrive sur les lieux. ",

                          optionInput: (
                              <Input
                                  captionText={null}
                                  inputWidth="auto"
                                  placeholder=""
                                  borderColor="gray"
                                  borderWidth={1}
                                  value={branchSettings.ouverture}
                                  type="time"
                                  onChange={(value: any) =>
                                      setOpeningHour(value)
                                  }
                                  style={{
                                      inputStyle: {
                                          padding: "10px 5px",
                                          outline: "none",
                                      },
                                  }}
                              />
                          ),
                      },
                      {
                          titleColor: "#2A346D",
                          titleSize: "16px",
                          titleWeight: "bolder",
                          descriptionColor: "#6F78AB",
                          descriptionSize: "13",
                          descriptionWeight: "normal",
                          optionTitle: "Heure de fermeture",
                          optionDescription:
                              "L’heure à laquelle la dernière personne quitte les lieux . ",
                          optionInput: (
                              <Input
                                  captionText={null}
                                  inputWidth="auto"
                                  placeholder=""
                                  borderColor="gray"
                                  borderWidth={1}
                                  value={branchSettings.fermeture}
                                  onChange={(value: any) =>
                                      setClosingHour(value)
                                  }
                                  type="time"
                                  style={{
                                      inputStyle: {
                                          padding: "10px 5px",
                                          outline: "none",
                                      },
                                  }}
                              />
                          ),
                      },
                  ],
        },
        {
            headerTitle: "Gestion des repas et pauses",
            headerDescription: "",
            configOptions:
                branchSettings.useRepas || branchSettings.usePause
                    ? [
                          {
                              titleColor: "#2A346D",
                              titleSize: "16px",
                              titleWeight: "bolder",
                              descriptionColor: "#6F78AB",
                              descriptionSize: "13",
                              descriptionWeight: "normal",
                              optionTitle: "Activer la gestion des repas",
                              optionDescription: "",
                              optionInput: (
                                  <ToggleButton
                                      isChecked={branchSettings.useRepas}
                                      onClick={(e: any) =>
                                          setUseRepas(e.target.checked)
                                      }
                                  />
                              ),
                          },
                          {
                              titleColor: "#2A346D",
                              titleSize: "16px",
                              titleWeight: "bolder",
                              descriptionColor: "#6F78AB",
                              descriptionSize: "13",
                              descriptionWeight: "normal",
                              optionTitle: "Activer la gestion des pauses",
                              optionDescription: "",
                              optionInput: (
                                  <ToggleButton
                                      isChecked={branchSettings.usePause}
                                      onClick={(e: any) =>
                                          setUsePause(e.target.checked)
                                      }
                                  />
                              ),
                          },
                          {
                              titleColor: "#2A346D",
                              titleSize: "16px",
                              titleWeight: "bolder",
                              descriptionColor: "#6F78AB",
                              descriptionSize: "13",
                              descriptionWeight: "normal",
                              optionTitle: "Pause/repas automatique",
                              optionDescription: "",
                              optionInput: "",
                              optionInput2: (
                                  <PauseConditions
                                      conditionsList={
                                          branchSettings.pauseRepasConditions
                                      }
                                      useRepas={branchSettings.useRepas}
                                      usePause={branchSettings.usePause}
                                  />
                              ),
                          },
                      ]
                    : [
                          {
                              titleColor: "#2A346D",
                              titleSize: "16px",
                              titleWeight: "bolder",
                              descriptionColor: "#6F78AB",
                              descriptionSize: "13",
                              descriptionWeight: "normal",
                              optionTitle: "Activer la gestion des repas",
                              optionDescription: "",
                              optionInput: (
                                  <ToggleButton
                                      isChecked={branchSettings.useRepas}
                                      onClick={(e: any) =>
                                          setUseRepas(e.target.checked)
                                      }
                                  />
                              ),
                          },
                          {
                              titleColor: "#2A346D",
                              titleSize: "16px",
                              titleWeight: "bolder",
                              descriptionColor: "#6F78AB",
                              descriptionSize: "13",
                              descriptionWeight: "normal",
                              optionTitle: "Activer la gestion des pauses",
                              optionDescription: "",
                              optionInput: (
                                  <ToggleButton
                                      isChecked={branchSettings.usePause}
                                      onClick={(e: any) =>
                                          setUsePause(e.target.checked)
                                      }
                                  />
                              ),
                          },
                      ],
        },
        {
            headerTitle: "Gestion des heures travaillées",
            headerDescription: "",
            configOptions: branchSettings.showNightHoursReport
                ? [
                      {
                          titleColor: "#2A346D",
                          titleSize: "16px",
                          titleWeight: "bolder",
                          descriptionColor: "#6F78AB",
                          descriptionSize: "13",
                          descriptionWeight: "normal",
                          optionTitle: "Activer les heures de nuit",
                          optionDescription: "",
                          optionInput: (
                              <ToggleButton
                                  isChecked={
                                      branchSettings.showNightHoursReport
                                  }
                                  onClick={(e: any) =>
                                      setShowHours({
                                          showNightHoursReport:
                                              e.target.checked,
                                      })
                                  }
                              />
                          ),
                      },
                      {
                          titleColor: "#2A346D",
                          titleSize: "16px",
                          titleWeight: "bolder",
                          descriptionColor: "#6F78AB",
                          descriptionSize: "13",
                          descriptionWeight: "normal",
                          optionTitle: "Heure de début de nuit",
                          optionDescription: "",
                          optionInput: (
                              <Input
                                  captionText={null}
                                  inputWidth="100px"
                                  placeholder=""
                                  borderColor="gray"
                                  borderWidth={1}
                                  value={`${branchSettings.nightHours.start}`}
                                  onChange={(value: any) =>
                                      setShowHours({
                                          nightHours: { start: value },
                                      })
                                  }
                                  type="time"
                                  style={{
                                      inputStyle: {
                                          padding: "10px 5px",
                                          outline: "none",
                                      },
                                  }}
                              />
                          ),
                      },
                      {
                          titleColor: "#2A346D",
                          titleSize: "16px",
                          titleWeight: "bolder",
                          descriptionColor: "#6F78AB",
                          descriptionSize: "13",
                          descriptionWeight: "normal",
                          optionTitle: "Heure de fin de nuit",
                          optionDescription: "",
                          optionInput: (
                              <Input
                                  captionText={null}
                                  inputWidth="100px"
                                  placeholder=""
                                  borderColor="gray"
                                  borderWidth={1}
                                  value={`${branchSettings.nightHours.end}`}
                                  onChange={(value: any) =>
                                      setShowHours({
                                          nightHours: { end: value },
                                      })
                                  }
                                  type="time"
                                  style={{
                                      inputStyle: {
                                          padding: "10px 5px",
                                          outline: "none",
                                      },
                                  }}
                              />
                          ),
                      },

                      {
                          titleColor: "#2A346D",
                          titleSize: "16px",
                          titleWeight: "bolder",
                          descriptionColor: "#6F78AB",
                          descriptionSize: "13",
                          descriptionWeight: "normal",
                          optionTitle: "Activer les heures de dimanche",
                          optionDescription: "",
                          optionInput: (
                              <ToggleButton
                                  isChecked={
                                      branchSettings.showSundayHoursReport
                                  }
                                  onClick={(e: any) =>
                                      setShowHours({
                                          showSundayHoursReport:
                                              e.target.checked,
                                      })
                                  }
                              />
                          ),
                      },
                      {
                          titleColor: "#2A346D",
                          titleSize: "16px",
                          titleWeight: "bolder",
                          descriptionColor: "#6F78AB",
                          descriptionSize: "13",
                          descriptionWeight: "normal",
                          optionTitle: "Activer les heures des jours fériés",
                          optionDescription: "",
                          optionInput: (
                              <ToggleButton
                                  isChecked={
                                      branchSettings.showHolidaysHoursReport
                                  }
                                  onClick={(e: any) =>
                                      setShowHours({
                                          showHolidaysHoursReport:
                                              e.target.checked,
                                      })
                                  }
                              />
                          ),
                      },
                  ]
                : [
                      {
                          titleColor: "#2A346D",
                          titleSize: "16px",
                          titleWeight: "bolder",
                          descriptionColor: "#6F78AB",
                          descriptionSize: "13",
                          descriptionWeight: "normal",
                          optionTitle: "Activer les heures de nuit",
                          optionDescription: "",
                          optionInput: (
                              <ToggleButton
                                  isChecked={
                                      branchSettings.showNightHoursReport
                                  }
                                  onClick={(e: any) =>
                                      setShowHours({
                                          showNightHoursReport:
                                              e.target.checked,
                                      })
                                  }
                              />
                          ),
                          // optionInput2: (
                          // nightHours.start
                          // nightHours.end
                          // )
                      },
                      {
                          titleColor: "#2A346D",
                          titleSize: "16px",
                          titleWeight: "bolder",
                          descriptionColor: "#6F78AB",
                          descriptionSize: "13",
                          descriptionWeight: "normal",
                          optionTitle: "Activer les heures de dimanche",
                          optionDescription: "",
                          optionInput: (
                              <ToggleButton
                                  isChecked={
                                      branchSettings.showSundayHoursReport
                                  }
                                  onClick={(e: any) =>
                                      setShowHours({
                                          showSundayHoursReport:
                                              e.target.checked,
                                      })
                                  }
                              />
                          ),
                      },
                      {
                          titleColor: "#2A346D",
                          titleSize: "16px",
                          titleWeight: "bolder",
                          descriptionColor: "#6F78AB",
                          descriptionSize: "13",
                          descriptionWeight: "normal",
                          optionTitle: "Activer les heures des jours fériés",
                          optionDescription: "",
                          optionInput: (
                              <ToggleButton
                                  isChecked={
                                      branchSettings.showHolidaysHoursReport
                                  }
                                  onClick={(e: any) =>
                                      setShowHours({
                                          showHolidaysHoursReport:
                                              e.target.checked,
                                      })
                                  }
                              />
                          ),
                      },
                  ],
        },
        {
            headerTitle: "Registre du personnel",
            headerDescription:
                "Esperoo peut gérer le registre unique du personnel (RUP). Vous pouvez l’exporter (format PDF) ou l’envoyer par mail.",
            configOptions: [
                {
                    titleColor: "#2A346D",
                    titleSize: "16px",
                    titleWeight: "bolder",
                    descriptionColor: "#6F78AB",
                    descriptionSize: "13",
                    descriptionWeight: "normal",
                    optionTitle: "Activer le registre du personnel",
                    optionDescription:
                        "Ce n’est pas une obligation d’utiliser le RUP avec Esperoo. Vous pouvez continuer à le faire sur papier mais assurez vous qu’il soit complet. Le registre unique du personnel peut être tenu sur un support numérique.",
                    optionInput: (
                        <ToggleButton
                            isChecked={branchSettings.register}
                            onClick={(e: any) =>
                                setEnableRegister(e.target.checked)
                            }
                        />
                    ),
                },
            ],
        },
        {
            headerTitle: "Masse salariale",
            headerDescription:
                "La masse salariale est le cumul des rémunérations brutes des salariés de l'établissement (hors cotisations patronales). ",
            configOptions: [
                {
                    titleColor: "#2A346D",
                    titleSize: "16px",
                    titleWeight: "bolder",
                    descriptionColor: "#6F78AB",
                    descriptionSize: "13",
                    descriptionWeight: "normal",
                    optionTitle: "Calcul de la masse salariale",
                    optionDescription:
                        "Cette fonctionnalité permet à l'employeur de calculer le masse salariale, en tenant compte de certains facteurs.",
                    optionInput: (
                        <ToggleButton
                            isChecked={branchSettings.paie}
                            onClick={(e: any) =>
                                setEnablePayroll(e.target.checked)
                            }
                        />
                    ),
                },
            ],
        },
        CalculSalaireSettings(),
    ];
};
