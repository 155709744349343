import * as React from "react";
import TextData from "../../../atoms/TextData";
import TableWeekHeader from "../../../molecules/TableWeekHeader";
import EmployeeInfoCellRow from "../../../molecules/EmployeeInfoCellRow";
import EmployeeInfoHeaderRow from "../../../molecules/EmployeeInfoHeaderRow";
import ReportNoRecord from "../../../templates/ReportNoRecord";
import Button from "../../../atoms/Button";
import Loading from "../../../atoms/Loading";
import ToggleButton from "../../../atoms/Toggle";
import SelectInput from "../../../atoms/SelectInput";

import { useReport } from "../../../../hooks/useReport";
import { useLanguage } from "../../../../hooks/useLanguage";
import moment from "moment";
import {
    employeeRoleColor,
    formatTime,
    convertMinutesToHours,
} from "../../../../_helpers/Functions";
import { useBranch } from "../../../../hooks/useBranch";
import { useEmployees } from "../../../../hooks/useEmployees";
import Table from "../../Table";
import { ROUTER } from "../../../../constants/env";
import { EmployeeWorkingData } from "../../../../redux/features/reports/types";
import { useTimeTracker } from "../../../../hooks/useTimeTracker";
import Swal from "sweetalert2";
import NewServiceCard from "../../NewServiceCard";
import Modal from "../../../atoms/Modal";
import WorkHoursOverviewCard from "../../WorkHoursOverviewCard";
import "./style.scss";
import WarningPanel from "../../../atoms/WarningPanel";
import { clear } from "console";
import { createFalse, createNonNullChain } from "typescript";

import { ReactComponent as FullScreenSVG } from "./fullscreen.svg";
import { ReactComponent as ExitFullScreenSVG } from "./exitfullscreen.svg";
import { ReactComponent as DownloadSVG } from "./download.svg";
import { ReactComponent as DownArrowSVG } from "./downArrow.svg";

import Input from "../../../atoms/Input";
import { WorkedHoursTableRightMenuProps } from "./WorkedHoursTableRightMenuProps";
import { useLogin } from "../../../../hooks/useLogin";
function WorkedHoursTable() {
    const {
        report,
        getReportFromLocalStorage,
        weeks,
        getWeeklyWorkHours,
        getEmployeeFullReport,
        setFirstDayOfWeek,
        setSelectedEmployee,
        setShowedEmployee,
        setLastDayOfWeek,
        resetSelectedShift,
        generateAttendanceSheet,
        handleSetWorkHoursDataLength,
    } = useReport();
    const {
        selectedBranchSettings,
        getBranchSettings,
        Settings,
        branchSettings,
    } = useBranch();
    const {
        getEmployees,
        employees,
        filteredFromAllSites,
        allEmpsInApp,
        onUpdateFilterBy,
        filterBy,
        getPrimesTypes,
    } = useEmployees();
    const { getTimeTracker } = useTimeTracker();
    const timeFomatter = (timeInMinutes: any) => {
        if (!report?.showWorkedHoursInMinutes)
            return convertMinutesToHours(timeInMinutes);
        else return formatTime(timeInMinutes);
    };
    const [isOpen, setIsOpen] = React.useState(false);

    const DataLoading = () => {
        let res: any[] = [];
        if (!report.selectedEmployee && report.employeesData) {
            //Object.values(report.employeesData)?
            let rowsIndex = -1;
            res = allEmpsInApp?.map((emp: any, index: number) => {
                const employee = report.employeesData[emp?._id];
                if (employee) {
                    rowsIndex++;
                    const firstCell = {
                        value: (
                            <EmployeeInfoHeaderRow
                                firstName={employee?.fname}
                                lastName={employee?.lname}
                                position={employee?.role}
                                dotColor={employeeRoleColor(employee?.role)}
                                weekHours={employee?.contractHours + " h"}
                                profileImage={employee?.picture}
                            />
                        ),
                        name: `${employee?.fname} ${employee?.lname}`,
                        style: {
                            borderRight: "1px solid #EDEFF5",
                        },
                        onClickCell: () => {},
                    };
                    const ClassName =
                        rowsIndex % 2 === 1 ? "odd-row" : "even-row";
                    const workHoursCells = () => {
                        if (report.year === null || report.month === null) {
                            return [];
                        }
                        return employee?.workedHours.map((workHours: any, index: number) => {
                            const {
                                week,
                                totalWorkedHours,
                                extraWorkedHours,
                                missingWorkedHours,
                            } = workHours;
                            let isoYear = report.year;
                            if (employee?.workedHours?.[index - 1]) {
                                if (employee?.workedHours?.[index - 1].week > week) {
                                    isoYear++;
                                }
                            }
                            const diffValue =
                                missingWorkedHours > 0
                                    ? `-${timeFomatter(missingWorkedHours)}`
                                    : `+${timeFomatter(extraWorkedHours)}`;
                            const displayDiffValue = () => {
                                if (diffValue.includes("-")) {
                                    return !report.hideRemainingContractHours;
                                } else if (diffValue.includes("+")) {
                                    return !report.hideExtraContractHours;
                                } else {
                                    return true;
                                }
                            };
                            const diffColor =
                                missingWorkedHours > 0 ? "#00A42E" : "#FB3640";
                            const employeeWorkHoursData = () => {
                                if (displayDiffValue()) {
                                    return [
                                        {
                                            value: timeFomatter(
                                                totalWorkedHours,
                                            ),
                                            fontWeight: "bold",
                                            width: "auto",
                                            color: "#4C5690",
                                            size: "14px",
                                        },
                                        {
                                            value: diffValue,
                                            width: "auto",
                                            color: diffColor,
                                            size: "14px",
                                        },
                                    ];
                                } else {
                                    return [
                                        {
                                            value: timeFomatter(
                                                totalWorkedHours,
                                            ),
                                            fontWeight: "bold",
                                            width: "auto",
                                            color: "#4C5690",
                                            size: "14px",
                                        },
                                    ];
                                }
                            };
                            return {
                                value: (
                                    <EmployeeInfoCellRow
                                        data={employeeWorkHoursData()}
                                    />
                                ),
                                name: week,
                                style: {
                                    borderRight: "1px solid #EDEFF5",
                                },
                                onClickCell: async () => {
                                    const firstDayOfWeek = moment()
                                        .isoWeekYear(isoYear)
                                        .isoWeek(week)
                                        .startOf("week")
                                        .format("D MMM YYYY");
                                    const lastDayOfWeek = moment()
                                        .isoWeekYear(isoYear)
                                        .isoWeek(week)
                                        .endOf("week")
                                        .format("D MMM YYYY");
                                    setFirstDayOfWeek(firstDayOfWeek);
                                    setLastDayOfWeek(lastDayOfWeek);
                                    setShowedEmployee(employee);
                                    // await getWeeklyWorkHours(
                                    //     week,
                                    //     employee?.id,
                                    // );
                                    await getEmployeeFullReport(
                                        week,
                                        isoYear,
                                        employee?.id,
                                    );
                                    setIsOpen(true);
                                },
                            };
                        });
                    };

                    const additionalData = () => {
                        const missingMonthlyWorkedHours =
                            employee?.missingMonthlyWorkedHours;
                        const extraMonthlyWorkedHours =
                            employee?.extraMonthlyWorkedHours;
                        const diffValue =
                            missingMonthlyWorkedHours > 0
                                ? `-${timeFomatter(missingMonthlyWorkedHours)}`
                                : `+${timeFomatter(extraMonthlyWorkedHours)}`;
                        const displayDiffValue = () => {
                            if (diffValue.includes("-")) {
                                return !report.hideRemainingContractHours;
                            } else if (diffValue.includes("+")) {
                                return !report.hideExtraContractHours;
                            } else {
                                return true;
                            }
                        };
                        const diffColor =
                            missingMonthlyWorkedHours > 0
                                ? "#00A42E"
                                : "#FB3640";
                        const totalHoursData = displayDiffValue()
                            ? [
                                  {
                                      value: timeFomatter(
                                          employee?.totalWorkedHoursOfMonth,
                                      ),
                                      fontWeight: "bold",
                                      width: "auto",
                                      color: "#4C5690",
                                      size: "14px",
                                  },
                                  {
                                      value: diffValue,
                                      fontWeight: "bold",
                                      width: "auto",
                                      color: diffColor,
                                      size: "14px",
                                  },
                              ]
                            : [
                                  {
                                      value: timeFomatter(
                                          employee?.totalWorkedHoursOfMonth,
                                      ),
                                      fontWeight: "bold",
                                      width: "auto",
                                      color: "#4C5690",
                                      size: "14px",
                                  },
                              ];
                        const totalWorkedMonthlyHours = {
                            value: (
                                <EmployeeInfoCellRow data={totalHoursData} />
                            ),
                            name: "phone",
                            style: {
                                borderRight: "1px solid #EDEFF5",
                            },
                        };
                        const totalWorkedDays = () =>
                            //Settings?.branch?.salaries === "daily" &&
                            report?.showWorkedHours
                                ? {
                                      value: (
                                          <EmployeeInfoCellRow
                                              data={[
                                                  {
                                                      value: String(
                                                          employee?.totalWorkedDays,
                                                      ),
                                                      fontWeight: "bold",
                                                      width: "auto",
                                                      color: "#4C5690",
                                                      size: "14px",
                                                  },
                                              ]}
                                          />
                                      ),
                                      name: "phone",
                                      style: {
                                          borderRight: "1px solid #EDEFF5",
                                      },
                                  }
                                : {};
                        const totalPause = () =>
                            //Settings?.timeTracker?.allowPauses &&
                            report?.showBreaks && branchSettings.usePause
                                ? {
                                      value: (
                                          <EmployeeInfoCellRow
                                              data={[
                                                  {
                                                      value: timeFomatter(
                                                          employee?.totalPauses,
                                                      ),
                                                      fontWeight: "bold",
                                                      width: "auto",
                                                      color: "#4C5690",
                                                      size: "14px",
                                                  },
                                              ]}
                                          />
                                      ),
                                      name: "phone",
                                      style: {
                                          borderRight: "1px solid #EDEFF5",
                                      },
                                  }
                                : {};
                        const totalMeal = () =>
                            //Settings?.timeTracker?.repas &&
                            report?.showMeals && branchSettings.useRepas
                                ? {
                                      value: (
                                          <EmployeeInfoCellRow
                                              data={[
                                                  {
                                                      value: String(
                                                          employee?.totalMeals,
                                                      ),
                                                      fontWeight: "bold",
                                                      width: "auto",
                                                      color: "#4C5690",
                                                      size: "14px",
                                                  },
                                              ]}
                                          />
                                      ),
                                      name: "phone",
                                      style: {
                                          borderRight: "1px solid #EDEFF5",
                                      },
                                  }
                                : {};
                        const totalPay = () =>
                            Settings?.branch?.paie && {
                                value: (
                                    <EmployeeInfoCellRow
                                        data={[
                                            {
                                                value: `${employee?.totalPay}`,
                                                fontWeight: "bold",
                                                width: "auto",
                                                color: "#4C5690",
                                                size: "14px",
                                            },
                                        ]}
                                    />
                                ),
                                name: "phone",
                                style: {
                                    borderRight: "1px solid #EDEFF5",
                                },
                            };
                        return [
                            totalWorkedMonthlyHours,
                            totalWorkedDays(),
                            totalPause(),
                            totalMeal(),
                            //totalPay(),
                        ].filter(
                            (item) =>
                                item &&
                                typeof item === "object" &&
                                Object.keys(item).length > 0,
                        );
                    };
                    const dataRows = [
                        firstCell,
                        ...workHoursCells(),
                        ...additionalData(),
                    ];
                    return {
                        dataRow: dataRows,
                        onClickRow: () => {},
                        style: {
                            cursor: "pointer",
                            border: "1px solid #EDEFF5",
                        },
                        className: ["hover:bg-slate-100", ClassName],
                    };
                } else {
                    return null;
                }
            });
            handleSetWorkHoursDataLength(rowsIndex + 1);
        }
        /*
        else {
            const selectedEmp: any = Object.values(report.employeesData)?.find(
                (emp: any) => emp.id === report.selectedEmployee?.id
            );
            //rest of the code
            if (selectedEmp) {
                const {
                    id,
                    fname,
                    lname,
                    role,
                    picture,
                    contractHours,
                    workedHours,
                    totalWorkedHoursOfMonth,
                    totalPauses,
                    totalMeals,
                    totalPay,
                } = selectedEmp;

                const firstCell = {
                    value: (
                        <EmployeeInfoHeaderRow
                            firstName={fname}
                            lastName={lname}
                            position={role}
                            dotColor={employeeRoleColor(role)}
                            weekHours={contractHours}
                            profileImage={ROUTER.STATIC_IMG(picture)}
                        />
                    ),
                    name: `${fname} ${lname}`,
                    style: {
                        borderRight: "1px solid #EDEFF5",
                    },
                    onClickCell: () => {},
                };

                const workHoursCells = () => {
                    if (report.year === null || report.month === null) {
                        return [];
                    }
                    return workedHours.map((workHours: any) => {
                        const {
                            week,
                            totalWorkedHours,
                            extraWorkedHours,
                            missingWorkedHours,
                        } = workHours;
                        const diffValue =
                            missingWorkedHours > 0
                                ? `-${timeFomatter(missingWorkedHours)}`
                                : `+${timeFomatter(extraWorkedHours)}`;
                        const displayDiffValue = () => {
                            if (diffValue.includes("-")) {
                                return !report.hideRemainingContractHours;
                            } else if (diffValue.includes("+")) {
                                return !report.hideExtraContractHours;
                            } else {
                                return true;
                            }
                        };
                        const diffColor = missingWorkedHours
                            ? "#00A42E"
                            : "#FB3640";
                        const employeeWorkHoursData = () => {
                            if (displayDiffValue()) {
                                return [
                                    {
                                        value: timeFomatter(totalWorkedHours),
                                        fontWeight: "bold",
                                        width: "auto",
                                        color: "#4C5690",
                                        size: "14px",
                                    },
                                    {
                                        value: diffValue,
                                        fontWeight: "bold",
                                        width: "auto",
                                        color: diffColor,
                                        size: "14px",
                                    },
                                ];
                            } else {
                                return [
                                    {
                                        value: timeFomatter(totalWorkedHours),
                                        fontWeight: "bold",
                                        width: "auto",
                                        color: "#4C5690",
                                        size: "14px",
                                    },
                                ];
                            }
                        };
                        return {
                            value: (
                                <EmployeeInfoCellRow
                                    data={employeeWorkHoursData()}
                                />
                            ),
                            name: week,
                            style: {
                                borderRight: "1px solid #EDEFF5",
                            },
                            onClickCell: async () => {
                                const firstDayOfWeek = moment()
                                    .week(week)
                                    .startOf("week")
                                    .format("D MMM YYYY");
                                const lastDayOfWeek = moment()
                                    .week(week)
                                    .endOf("week")
                                    .format("D MMM YYYY");
                                setFirstDayOfWeek(firstDayOfWeek);
                                setLastDayOfWeek(lastDayOfWeek);
                                setShowedEmployee(selectedEmp);
                                // await getWeeklyWorkHours(week, selectedEmp.id);
                                await getEmployeeFullReport(
                                        week,
                                        selectedEmp.id,
                                    );
                                setIsOpen(true);
                            },
                        };
                    });
                };
                const additionalData = () => {
                    const totalWorkedMonthlyHours = {
                        value: (
                            <EmployeeInfoCellRow
                                data={[
                                    {
                                        value: timeFomatter(
                                            totalWorkedHoursOfMonth
                                        ),
                                        fontWeight: "bold",
                                        width: "auto",
                                        color: "#4C5690",
                                        size: "14px",
                                    },
                                ]}
                            />
                        ),
                        name: "phone",
                        style: {
                            borderRight: "1px solid #EDEFF5",
                        },
                    };
                    const totalWorkedDays = {
                        value: (
                            <EmployeeInfoCellRow
                                data={[
                                    {
                                        value: String(
                                            selectedEmp.totalWorkedDays
                                        ),
                                        fontWeight: "bold",
                                        width: "auto",
                                        color: "#4C5690",
                                        size: "14px",
                                    },
                                ]}
                            />
                        ),
                        name: "phone",
                        style: {
                            borderRight: "1px solid #EDEFF5",
                        },
                    };
                    const totalPause = () =>
                        // Settings?.timeTracker?.allowPauses &&
                        ({
                            value: (
                                <EmployeeInfoCellRow
                                    data={[
                                        {
                                            value: String(totalPauses),
                                            fontWeight: "bold",
                                            width: "auto",
                                            color: "#4C5690",
                                            size: "14px",
                                        },
                                    ]}
                                />
                            ),
                            name: "phone",
                            style: {
                                borderRight: "1px solid #EDEFF5",
                            },
                        });
                    const totalMeal = () =>
                        Settings?.timeTracker?.repas && {
                            value: (
                                <EmployeeInfoCellRow
                                    data={[
                                        {
                                            value: String(totalMeals),
                                            fontWeight: "bold",
                                            width: "auto",
                                            color: "#4C5690",
                                            size: "14px",
                                        },
                                    ]}
                                />
                            ),
                            name: "phone",
                            style: {
                                borderRight: "1px solid #EDEFF5",
                            },
                        };
                    const totalPay = () =>
                        // Settings?.branch?.paie &&
                        ({
                            value: (
                                <EmployeeInfoCellRow
                                    data={[
                                        {
                                            value: `${selectedEmp.totalPay}`,
                                            fontWeight: "bold",
                                            width: "auto",
                                            color: "#4C5690",
                                            size: "14px",
                                        },
                                    ]}
                                />
                            ),
                            name: "phone",
                            style: {
                                borderRight: "1px solid #EDEFF5",
                            },
                        });
                    return [
                        totalWorkedMonthlyHours,
                        totalWorkedDays,
                        totalPause(),
                        totalMeal(),
                        //totalPay(),
                    ].filter(
                        (item) =>
                            item &&
                            typeof item === "object" &&
                            Object.keys(item).length > 0
                    );
                };

                const dataRows = [
                    firstCell,
                    ...workHoursCells(),
                    ...additionalData(),
                ];

                res = [
                    {
                        dataRow: dataRows,
                        onClickRow: () => {},
                        style: {
                            cursor: "pointer",
                            border: "1px solid #EDEFF5",
                        },
                        className: ["hover:bg-slate-100", "odd-row"],
                    },
                ];
            } else {
                res = [];
            }
        }*/
        if (res && res?.length > 0) {
            return res.filter((row) => row !== null);
        } else {
            return [];
        }
    };
    const additionalDataHeader = () => {
        const totalWorkedMonthlyHours = {
            title: (
                <TextData
                    color="#2A346D"
                    size="16px"
                    fontWeight="bold"
                    width="100%"
                >
                    Total
                </TextData>
            ),
            name: "totalWorkedMonthlyHours",
            style: {
                borderRight: "1px solid #EDEFF5",
                textAlign: "center" as "center",
            },
        };
        const totalWorkedDays = () =>
            //Settings?.branch?.salaries === "daily" &&
            {
                if (report?.showWorkedHours) {
                    return {
                        title: (
                            <TextData
                                color="#2A346D"
                                size="16px"
                                fontWeight="bold"
                                width="auto"
                                // style={{
                                //     marginLeft: "60px",
                                //     marginRight: "60px",
                                // }}
                            >
                                Jours travaillés
                            </TextData>
                        ),
                        name: "totalWorkedDays",
                        style: {
                            borderRight: "1px solid #EDEFF5",
                            textAlign: "center" as "center",
                        },
                    };
                } else return {};
            };
        const totalPause = () => {
            if (report?.showBreaks && branchSettings.usePause) {
                return {
                    //Settings?.timeTracker?.allowPauses &&
                    title: (
                        <TextData
                            color="#2A346D"
                            size="16px"
                            fontWeight="bold"
                            width="auto"
                            // style={{
                            //     marginLeft: "60px",
                            //     marginRight: "60px",
                            // }}
                        >
                            Pauses
                        </TextData>
                    ),
                    name: "totalPause",
                    style: {
                        borderRight: "1px solid #EDEFF5",
                        textAlign: "center" as "center",
                    },
                };
            } else return {};
        };
        const totalMeal = () => {
            //Settings?.timeTracker?.repas &&
            if (report?.showMeals && branchSettings.useRepas) {
                return {
                    title: (
                        <TextData
                            color="#2A346D"
                            size="16px"
                            fontWeight="bold"
                            width="auto"
                            // style={{
                            //     marginLeft: "60px",
                            //     marginRight: "60px",
                            // }}
                        >
                            Repas
                        </TextData>
                    ),
                    name: "totalMeal",
                    style: {
                        borderRight: "1px solid #EDEFF5",
                        textAlign: "center" as "center",
                    },
                };
            } else return {}; //
        };
        const totalPay = () =>
            Settings?.branch?.paie && {
                title: (
                    <TextData
                        color="#2A346D"
                        size="16px"
                        fontWeight="bold"
                        width="auto"
                        // style={{
                        //     marginLeft: "60px",
                        //     marginRight: "60px",
                        // }}
                    >
                        Paie
                    </TextData>
                ),
                name: "totalPay",
                style: {
                    textAlign: "center" as "center",
                },
            };
        return [
            totalWorkedMonthlyHours,
            totalWorkedDays(),
            totalPause(),
            totalMeal(),
            //totalPay(),
        ].filter(
            (item) =>
                item &&
                typeof item === "object" &&
                Object.keys(item).length > 0,
        );
    };
    const weekHeader = () => {
        const res = weeks?.map((week, index) => {
            const weekName = `S${week}`;
            const startDate = moment()
                .year(report.year)
                .isoWeek(week)
                .startOf("isoWeek")
                .format("DD/MM");
            const endDate = moment()
                .year(report.year)
                .isoWeek(week)
                .endOf("isoWeek")
                .format("DD/MM");
            const weekPeriod = `${startDate} - ${endDate}`;
            const isValidWeek = report.weekValidation?.[index]?.isValidated;
            return {
                title: (
                    <TableWeekHeader
                        frontTitle={weekName}
                        frontSubTitle={weekPeriod}
                        tagCornerColor={isValidWeek ? "#00A42E" : "#FB3640"}
                        backBackgroundColor={
                            isValidWeek
                                ? "rgba(0, 164, 46, 0.2)"
                                : "transparent"
                        }
                        backTitle={isValidWeek ? "Feuille d'émargement" : null}
                        key={`S${index}`}
                        isValidWeek={isValidWeek}
                        onValidWeekClick={() => {
                            generateAttendanceSheet({ weekNumber: week });
                        }}
                    />
                ),
                name: "email",
                style: {
                    borderRight: "1px solid #EDEFF5",
                },
            };
        });
        return res;
    };
    const HeaderLoading = () => {
        const header = [
            {
                title: (
                    <TextData
                        color="#2A346D"
                        size="16px"
                        fontWeight="bold"
                        width="max-content"
                    >
                        Employé ({report?.dataLength?.workHours})
                    </TextData>
                ),
                name: "employee",
                style: {
                    padding: "10px 0",
                    minWidth: "200px",
                    paddingLeft: "15px",
                    borderRight: "1px solid #EDEFF5",
                },
            },
            ...weekHeader(),
            ...additionalDataHeader(),
        ];
        return header;
    };
    const WorkedHoursTableProps = {
        fields: {
            fieldRow: HeaderLoading(),
            onClickRow: () => {},
            style: {
                borderBottom: "1px solid #EDEFF5",
                position: "sticky" as "sticky",
                top: 0,
                backgroundColor: "white",
            },
        },
        data: [...DataLoading()],
    };
    const handleCloseWorkHoursOverviewCard = () => {
        setSelectedEmployee(null);
        setIsOpen(false);
        resetSelectedShift();
    };
    // const changeShift = (week: any, employeea: any) => {
    //   setSelectedWeek(week);
    //   setSelectedEmployee(week);
    // };
    if (report.month && report.year) {
        if (report.loading === true && isOpen === false) {
            return (
                <div
                    style={{
                        position: "relative",
                        width: "100%",
                        height: "calc(100vh - 250px)",
                    }}
                >
                    <Loading width="500px" height="250px" />
                </div>
            );
        } else if (WorkedHoursTableProps.data.length > 0) {
            return (
                <div className="worked-hours-table-container">
                    <Table {...WorkedHoursTableProps} />
                    <Modal
                        modalEnabled={isOpen}
                        onClose={handleCloseWorkHoursOverviewCard}
                    >
                        <WorkHoursOverviewCard
                            display={isOpen}
                            onClose={handleCloseWorkHoursOverviewCard}
                            selectedShift={report.selectedShift}
                        />
                    </Modal>
                </div>
            );
        } else {
            return (
                <div
                    style={{
                        // position: "relative",
                        width: "100%",
                        height: "calc(100vh - 250px)",
                    }}
                >
                    <ReportNoRecord />
                </div>
            );
        }
    } else {
        return (
            <div style={{ position: "relative" }}>
                <WarningPanel
                    title="Attention"
                    description="Veuillez sélectionner le mois et l'année pour afficher les données correspondantes."
                />
            </div>
        );
    }
}

function WorkedHoursTableHeader() {
    const {
        exportClassicReport,
        branchs,
        handleSetBranchs,
        selectedBranchs,
        handleSetSelectedBranchs,
    } = useReport();
    const { switchFullScreen, isFullScreen } = useLanguage();

    const toggleFullScreen = () => {
        switchFullScreen();
    };
    const { selectedBranchSettings } = useBranch();
    React.useEffect(() => {
        handleSetBranchs(handleBranchs());
    }, []);
    React.useEffect(() => {
        branchs?.map((item: any) => {
            if (selectedBranchSettings?._id === item.value) {
                handleSetSelectedBranchs([
                    {
                        ...item,
                    },
                ]);
            }
        });

        if (!selectedBranchSettings?._id) {
            handleSetSelectedBranchs(branchs);
        }
    }, [selectedBranchSettings, branchs]);

    function handleBranchs() {
        const sessionBranchs = sessionStorage.getItem("branchs");
        const sessionUser = sessionStorage.getItem("user");
        let sessionLicense: any[] = [];
        if (sessionUser) {
            sessionLicense = JSON.parse(sessionUser).realLicense;
        }
        if (sessionBranchs && sessionBranchs !== "[]") {
            let listOfBranchs = JSON.parse(sessionBranchs);
            if (listOfBranchs.length > 0) {
                if (sessionLicense.length > 1) {
                    return [
                        ...listOfBranchs.map((item: any) => {
                            const LicenseEnseigne = sessionLicense.find(
                                (license: any) => {
                                    return license._id === item.license;
                                },
                            );
                            let enseigneName = "";
                            if (LicenseEnseigne) {
                                enseigneName = LicenseEnseigne.enseigne;
                            }

                            return {
                                label: `${enseigneName} - ${item.name}`,
                                value: item._id,
                            };
                        }),
                    ];
                } else {
                    return [
                        ...listOfBranchs.map((item: any) => {
                            return { label: item.name, value: item._id };
                        }),
                    ];
                }
            }
            return [];
        }
        return [];
    }
    const [showExportBtnOptions, setShowExportBtnOptions] =
        React.useState(false);
    const [exportSilae, setExportSilae] = React.useState(false);
    const handleAttendanceButtonClick = (e: any) => {
        e.stopPropagation();
        setShowExportBtnOptions(!showExportBtnOptions);
    };

    const { auth } = useLogin();

    const handleExportSilaeORClassic = () => {
        exportClassicReport({ exportSilae });
    };
    const { branchSettings } = useBranch();
    return (
        <div
            style={{
                display: "flex",
                width: "auto",
                justifyContent: "space-between",
                alignItems: "center",
                height: "80px",
                margin: "0 1.25rem",
                gap: 20,
            }}
        >
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "20px",
                }}
            >
                <TextData color="#11106E" size="34" fontWeight="bold">
                    Rapports
                </TextData>
                <button className="fullscreen-btn" onClick={toggleFullScreen}>
                    <div className="fullscreen-icon">
                        {isFullScreen ? (
                            <ExitFullScreenSVG
                                height={18}
                                width={18}
                                color="white"
                                style={{
                                    cursor: "pointer",
                                }}
                            />
                        ) : (
                            <FullScreenSVG
                                height={18}
                                width={18}
                                color="white"
                                style={{
                                    cursor: "pointer",
                                }}
                            />
                        )}
                    </div>
                    <div className="fullscreen-title">
                        <div>
                            {!isFullScreen
                                ? "Mode plein écran"
                                : "Quitter le mode plein écran"}
                        </div>
                    </div>
                </button>
            </div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 20,
                }}
            >
                {branchs?.length > 1 && (
                    <SelectInput
                        title=""
                        placeholder="Sélectionner les sites"
                        options={branchs}
                        selectedValue={selectedBranchs}
                        isMultiple
                        style={{
                            fontSize: "16px",
                            gap: "0.25rem",
                            marginTop: "1rem",
                            // alignSelf: "flex-end",
                            marginBottom: "0.5rem",
                            borderRadius: "10px",
                        }}
                        onSelectOption={(selectedBranchs) => {
                            handleSetSelectedBranchs(selectedBranchs);
                        }}
                    />
                )}
                {branchSettings.enableSilae &&
                auth.user.license._id === "657323e7add9d26312c411b8" ? (
                    <AttendanceButton
                        showExportBtnOptions={showExportBtnOptions}
                        handleAttendanceButtonClick={
                            handleAttendanceButtonClick
                        }
                        exportSilae={exportSilae}
                        setExportSilae={setExportSilae}
                        handleExportSilaeORClassic={handleExportSilaeORClassic}
                    />
                ) : (
                    <Button
                        textColor="white"
                        backgroundColor="#2A8BAB"
                        style={{
                            fontSize: "16px",
                            gap: "0.25rem",
                            marginTop: "1rem",
                            // alignSelf: "flex-end",
                            marginBottom: "0.5rem",
                        }}
                        width="auto"
                        padding="0.75rem 20px"
                        height="auto"
                        onClick={exportClassicReport}
                    >
                        Télécharger le rapport
                    </Button>
                )}
            </div>
        </div>
    );
}

const AttendanceButton = ({
    showExportBtnOptions,
    handleAttendanceButtonClick,
    exportSilae,
    setExportSilae,
    handleExportSilaeORClassic,
}: any) => {
    return (
        <div style={{ display: "flex", position: "relative", zIndex: "99" }}>
            <Button
                width="fit-content"
                height="auto"
                backgroundColor="#2A8BAB"
                style={{
                    borderRadius: "8px",

                    fontSize: "16px",
                    gap: "0.4rem",
                    marginTop: "1rem",
                    // alignSelf: "flex-end",
                    marginBottom: "0.5rem",
                }}
                padding="0.75rem 10px"
                className={["attendance-button"]}
                onClick={handleExportSilaeORClassic}
            >
                <DownloadSVG height={20} width={20} />
                <TextData color="#fff" size="13.5px" fontWeight="500">
                    Télécharger le rapport
                </TextData>
                <div
                    onClick={handleAttendanceButtonClick}
                    style={{
                        borderLeft: "1px solid #CFE0E6",
                        padding: "5px",
                    }}
                >
                    <DownArrowSVG
                        color="#CFE0E6"
                        width={14}
                        height={14}
                        style={{
                            transform: showExportBtnOptions
                                ? "rotate(180deg)"
                                : "",
                            transition: "0.2s ease",
                        }}
                    />
                </div>
            </Button>
            {showExportBtnOptions && (
                <>
                    <div className="attendance-export-container">
                        <TextData
                            color="#8E96C1"
                            size="10px"
                            fontWeight="700"
                            style={{ textTransform: "uppercase" }}
                        >
                            Options d'export
                        </TextData>
                        <hr />
                        <div className="flex flex-col gap-5">
                            <div className="export-toogle-box flex justify-between">
                                <TextData
                                    color="#2A346D"
                                    size="12px"
                                    fontWeight="400"
                                >
                                    Rapport classique
                                </TextData>
                                <ToggleButton
                                    isChecked={!exportSilae}
                                    onClick={() =>
                                        setExportSilae((prev: boolean) => !prev)
                                    }
                                />
                            </div>
                            <div className="export-toogle-box flex justify-between">
                                <TextData
                                    color="#2A346D"
                                    size="12px"
                                    fontWeight="400"
                                >
                                    Silae
                                </TextData>
                                <ToggleButton
                                    isChecked={exportSilae}
                                    onClick={() =>
                                        setExportSilae((prev: boolean) => !prev)
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

const WorkedHoursTableRightMenu = WorkedHoursTableRightMenuProps;
export { WorkedHoursTable, WorkedHoursTableRightMenu, WorkedHoursTableHeader };
