import React from "react";
import { generatePicUrl } from "../../../_helpers/PictureHelpers";
import TableUserRole from "../TableUserRole";

export default function UserHeaderInfos({
    firstName,
    lastName,
    profilePic,
    reference,
    role,
    withRole,
}: UserHeaderInfosInterface) {
    return (
        <div>
            <div className="flex items-center  gap-3 grow">
                <div
                    className={`rounded-full w-[60px] h-[60px] p-[2px] border ${
                        role == "Administrator"
                            ? "border-admin"
                            : role == "Manager"
                              ? "border-manager"
                              : "border-employee"
                    }`}
                >
                    {profilePic ? (
                        <img
                            src={generatePicUrl(profilePic)}
                            className="w-full h-full rounded-full"
                            alt="profile image"
                        />
                    ) : (
                        <div className="h-full flex items-center justify-center text-default font-bold text-lg">
                            {firstName[0]}
                            {lastName[0]}
                        </div>
                    )}
                </div>

                <div>
                    {reference ? (
                        <span className="bg-n-0 rounded px-2 py-1 text-n-600 text-xs">
                            <span>Matricule: </span>
                            <b>{reference}</b>
                        </span>
                    ) : (
                        <span className="bg-n-0 rounded px-2 py-1 text-n-600 text-xs">
                            Aucune matricule
                        </span>
                    )}

                    <br />
                    <div className="flex gap-3">
                        <span className="text-n-800 font-bold text-3xl">
                            {firstName} {lastName}
                        </span>
                        {withRole && <TableUserRole role={role} />}
                    </div>
                </div>
            </div>
        </div>
    );
}

interface UserHeaderInfosInterface {
    profilePic?: string;
    role: "Administrator" | "Manager" | "Employee";
    firstName: string;
    lastName: string;
    reference: string;
    withRole?: boolean;
}
